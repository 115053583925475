export const GET_ERRORS = "GET_ERRORS";

export const MESSAGE = "MESSAGE"

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESSFUL = "REGISTER_SUCCESSFUL"


export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING"
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';


export const SET_PASSWORD = "SET_PASSWORD"
export const PASSWORD_SUCCESSFUL = "PASSWORD_SUCCESSFUL"


export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const PROFILE_UPDATED_SUCCESSFULLY = "PROFILE_UPDATED_SUCCESSFULLY";


export const ADD_BOOK = "ADD_BOOK"
export const BOOK_SUCCESSFUL = "BOOK_SUCCESSFUL"
export const DELETE_BOOK = "DELETE_BOOK"
export const ISSUE_BOOK = "ISSUE_BOOK"
export const BOOK_ISSUED_SUCCESSFULLY = "BOOK_ISSUED_SUCCESSFULLY";
export const RESERVE_BOOK = "RESERVE_BOOK";
export const BORROW_BOOK = "BORROW_BOOK"



export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER"

export const LOAD_USERS ='LOAD_USERS'
export const GET_USERS = 'GET_USERS'

export const ADD_LIBRARIAN = "ADD_LIBRARIAN";
export const DELETE_LIBRARIAN = "DELETE_LIBRARIAN"


export const SET_LOGIN = "SET_LOGIN"


export const INITIAL_USER_FETCH = 'INITIAL_USER_FETCH'
export const FETCH_USER_SUCCESSFUL = 'FETCH_USER_SUCCESSFUL'