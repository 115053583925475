// import axios from "axios";
import http from '../../utils/Axios'
import setAuthToken from "../../utils/setAuthToken";

import { getCurrentProfile, clearCurrentProfile } from './profileActions'


import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  REGISTER_USER,
  SET_LOGIN,
  REGISTER_SUCCESSFUL,
 
} from "./types";


const initialUserRegistration = () => {
    return {
      type: REGISTER_USER,
    };
}
const registrationSuccessful = () => {
    return {
      type: REGISTER_SUCCESSFUL,
    };
}

const initializeLogin = () => {
  return{
    type : SET_LOGIN
  }
}

// Register User
export const registerUser = (userData) => (dispatch) => {
    try {
           dispatch(initialUserRegistration());
           http
             .post(
               "/api/auth/register",
               userData
             )
               .then((res) =>{
                   dispatch(registrationSuccessful())
                   console.log(res)
                   
               }
               )
               .catch(err => {
                   console.log(err)
                   dispatch({
                     type: GET_ERRORS,
                     payload: err.response.data.msg,
                   });
               })
            
    }catch(err){
         console.log(err);
        dispatch({
            type: GET_ERRORS,
            payload : err.response.data.msg
        })
    }
 
};


// Login - Get User Token
export const loginUser = (userData) => async(dispatch) => {
    try {
      dispatch(initializeLogin())
     const res = await http
        .post(
          "/api/auth/login",
          userData
           )


            const { token, _id } = await res.data.data;
              
             // Set token to ls
            localStorage.setItem("jwtToken", token);
           localStorage.setItem('userId', _id)
             // Set token to Auth header
             setAuthToken(token);
             // Decode token to get user data
             const decoded = jwt_decode(token);
             
           dispatch(setCurrentUser(decoded))
           
            await dispatch(getCurrentProfile(localStorage.userId))
            
    } catch (err) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message
        });
    }
 
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};


// Log user out
export const logoutUser = () => (dispatch) => {

  setAuthToken(false);
  dispatch(setCurrentUser({}));

  dispatch(clearCurrentProfile({}))

  localStorage.clear()
  
};




// save user details

