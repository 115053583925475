  import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../utils/Axios'
import DataTable from 'react-data-table-component';


import { ModalProvider, Modal, ModalTransition } from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";




const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="my-2 p-2 border border-gray-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Name, Email" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);

const UserList = ( ) => {

  const token = localStorage.jwtToken;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false)
  const [ userId, setUserId ] = useState('')
  

const notify = (message) => toast.success( message, {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps

  // fetch users
  const fetchUsers = (() => {
  
    setLoading(true)
    
    http.get(`/api/auth/members/${localStorage.userId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(`from fetch user ${res}`)
        setUsers(res.data.data);
        setLoading(false)
      })
      .catch((error) => {
        error.message === 'Network Error' ? failedNotify('Network Error') : failedNotify('Connection Timeout') 
        setLoading(false)
      })
      

    })


    // delete users

    const deleteUser = async (data) => {
      setLoading(true)
      try {
        const response = await http
        .post("/api/auth/remove/member", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            Authorization: "Bearer " + token,
          },
        })
        console.log(response)

        if(response.data.status === 0){
          setLoading(false)
          setIsModalOpen(false);
          notify('User Deleted Successfully')
          fetchUsers()
        }
        
      } catch (error) {
        setLoading(false)
        setIsModalOpen(false)
        error.message === 'Network Error' ? failedNotify('Network Error') : failedNotify('Connection Timeout') 
      }
    }
 useEffect(() => {
   fetchUsers()
 }, [])
    



  // openModalHandler
  const modalHandler = () => setIsModalOpen(true);

  
 

  
  const columns = [
    {
      name: 'Name',
      cell : users => <div>{ users.firstName} {users.lastName}</div>,
      sortable: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      maxWidth: '800px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: users => <div>{ users.email }</div>
    },
    {
      name : 'Phone Number',
      cell : users => <div>{ users.phone }</div>
    },
    {
      name: 'Address',
      cell : users => <div>{ users.address}</div>,
      sortable: true,
      
    },
    {
      name : 'Status',
      cell : users => <div>{ users.membership ? 'Registered' : 'Not Registered'}</div>
    },
    
      {
      name: 'Action',
      button: true,
      cell:  users => <svg
      onClick={ () => {
        modalHandler();
        setUserId(users._id);}
      }
      className="w-6 h-6 stroke-current text-red-600 "
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headRow: {
        style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderColor: 'red'
      },
    },
    headCells: {
      style: {
        backgroundColor: 'green'
      },
    },
    cells: {
      style: {
        
      },
    },
  };



  // search functions logic
  const [filterText, setFilterText] = React.useState('');

const searchInputField = (rows) => {
  if(!rows) return []
  return rows.filter(
    (row) =>
      row.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.email.toLowerCase().includes(filterText.toLowerCase()) 
    
  );
};


  return (
    <div>
      <ToastContainer />
      <ModalProvider>
        
          <div className=" p-5 w-full">
            
            <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
          <DataTable
        title="Manage Users"
        columns={columns}
        data={searchInputField(users)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
            <Modal
              id="any-unique-identifier"
              isOpen={isModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to delete this Member?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={ () => {
                      deleteUser({ _uid: userId, _id: localStorage.userId });
                    }}
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    { loading ? 'Processing' : 'Proceed '}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        
      </ModalProvider>
    </div>
  );
};



export default UserList
