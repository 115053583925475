import React from "react";

const NotFound = () => {
  return (
    <div className="flex content-center items-center justify-center min-h-screen-75">
      <h3 className="pt-16 text-4xl">404 Page Not Found.</h3>
    </div>
  );
};
export default NotFound;
