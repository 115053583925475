import React from 'react'
import BackgroundImage from '../../assets/img/schoolImage.jpg'
// import { Link } from 'react-router-dom'

const Service = () => {
    return (
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-top bg-cover"
            style={{
              backgroundImage: `url(${BackgroundImage})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div>
                  <p className="my-6  text-white text-3xl font-bold md:text-5xl">
                    Using the Library
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-white -mt-24">
          <div className="container mx-auto">
            <div className="flex ">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-bold">Getting Oriented</h6>
                    <p className="mt-2 mb-4 text-gray-600 ">
                    knowledge is power and relevant information is key to elimination of uncertainty.
FMCA Medical Library is here to educate, enlighting, assist and guide our users on how to familiarize, identify, understand and utilize our information resources.
Come and get Oriented
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Borrowing Materials
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    Our Medical Library is stocked with up-to-date/modern medical information resources.
Books borrowed using the online resources will be reserved for physical pickup from the library at your convenience

                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Get a Library Card
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    Get registered to access our numerous e-books, medical databases, text-books, serials collection.
Visit our Library and obtain your library card or sign up for your online access code.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center mt-32">
              <div className="w-full  px-4 ">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-black">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 text-center font-semibold leading-normal">
                  Services
                </h3>

                <p className="text-lg font-medium text-center leading-relaxed mt-4 mb-4 text-gray-700">
                  The spectrum of services available in theFMCA Medical Library include:
                </p>
              </div>
              <div className="animate-bounce">
                <svg
                  className="w-16 h-20"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 17l-4 4m0 0l-4-4m4 4V3"
                  />
                </svg>
              </div>
            </div>
          </div>
        </section>
        <section className=" relative block bg-green-600">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-lg md:text-xl mt-5 font-bold text-white">
                  Service Sections of the Library include:
                </h6>
                <p className="mt-1 mb-2 text-white font-normal">
                  Cataloguing & Classification Section (headed by A .O.
                  OLORUNKALU)
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Circulation/User Service Section (headed by O .S. ORIMOGUNJE)
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Journal/Serial Section (headed by J .N. AGBENU)
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  E- Library/Internet Service Section
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Reprographic Section)
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Reference Section
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Acquisition/Collection development
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center"></div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-lg md:text-xl mt-5 font-bold text-white">
                  Services Provided:
                </h5>
                <p className="mt-1 mb-2 text-white font-normal">
                  Reference and referral services
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Information retrieval, management, storage and dissemination
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  HINARI/Database searches
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Rendering of current awareness services
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Book loan services: short or long time depending on the
                  material
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Computer/Internet services
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Internet services
                </p>
                <p className="mt-1 mb-2 text-white font-normal">
                  Photocopy and binding services
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default Service
