import React, { useState, useEffect } from 'react'
import moment from 'moment'
import http from '../../../utils/Axios'

import {
        ModalProvider,
        Modal,
      
        ModalTransition,
      } from "react-simple-hook-modal";
      import "react-simple-hook-modal/dist/styles.css";
      
      
      
      import Spinner from '../../../components/spinner/Spinner'

const UserReservedBook = () => {
        const [ userReserveBooks, setUserReserveBooks ] =useState([])
        const [ loading, setLoading ] = useState(false)

    const _id = localStorage.userId
    const [ isCancelReservedModal, setIsCancelReservedModal ] = useState(false)
    const [ singleBookData, setSingleBookData ] = useState({})


        const fetchReserveBooks = async () => {
                setLoading(true)
                try {
                        let response = await http.get(`/api/book/member-reserved-books/${_id}`)
                        console.log(response)
                        setLoading(false)
                        response.data.status ? setUserReserveBooks(response.data.data) : setUserReserveBooks(null)
                        
                } catch (error) {
                        console.log(error)
                }
        }

          // cancelReservedBook
  const cancelReservedBook = async ( data ) => {
        try {
          const response = await http.post("/api/book/cancel-reserve", data)
          if(response){
            fetchReserveBooks()
          }
        } catch (err) {
          console.log(err)
        }
      }
    

        useEffect(() =>{
                console.log('use effect triggered')
                fetchReserveBooks() 
        }, [])

        console.log(singleBookData)

        return (
                <div className="w-full ">
                <ModalProvider>
            {
                                loading ? ( <Spinner />) : (
                                        <div>
                                                {
                                                        !userReserveBooks ? (
                                                                <div className="flex items-center justify-center">
                                                                        <h1 className="text-lg">Sorry you don't have any reserved books available</h1>
                                                                </div>
                                                                
                                                        ) : (
                                                                <div className="flex flex-col">
  <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Book
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                category 
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
               Reserved Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
              
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              userReserveBooks && userReserveBooks.map(userReserveBook => {
                const { reservedDate  } = userReserveBook
                const newReservedDate = moment(reservedDate).format('dddd, MMMM Do YYYY')

                
                return(
                  <tr key={ userReserveBook._id }>
              <td className="px-6 py-4 whitespace-nowrap md:whitespace-wrap">
                <div className="flex items-center">
                  
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                    { userReserveBook.reserveBookId.title }
                    </div>
                    <div className="text-sm text-gray-500">
                      { userReserveBook.reserveBookId.author }
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{ userReserveBook.reserveBookId.category }</div>
                
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
              
              <div className="text-sm text-gray-900">{ newReservedDate }</div>
              </td>
              
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                 <button onClick={ () => {
                        setSingleBookData(userReserveBook )
                        setIsCancelReservedModal( true )
                        }} className="bg-red-400 text-white px-4 py-2 rounded-md shadow-lg">Cancel</button> 
                
              </td>
            </tr>
            
                )
              })
            }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
                                                        )
                                                }
                                        </div>
                                )
                        }
                        <Modal
              id="any-unique-identifier"
              isOpen={isCancelReservedModal}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to cancel issuing of this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsCancelReservedModal(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                   onClick={ async () => {
                        await cancelReservedBook({
                                _id : localStorage.userId,
                                reservedBookId : singleBookData.reserveBookId._id,
                                reservedId: singleBookData._id
                              }) 
                        setIsCancelReservedModal(false);
                   }

                   }
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>

            </ModalProvider>
        </div>
        )
}

export default UserReservedBook
