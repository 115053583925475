import React from 'react'
import { connect } from 'react-redux'
import { PDFDownloadLink } from "@react-pdf/renderer";
import LibraryCard from './LibraryCard';



const AboutUser = ({  profileDetails  }) => {
   
    const { libraryCard } = profileDetails

    return (
        <div className="py-5">
                    <h1 className="py-4 text-bold text-xl">Personal Information</h1>
                    <hr className="pb-3" />
                    <div className=" md:grid grid-cols-2 gap-4 py-3">
                            <div className="my-3">
                                <div className="flex justify-center items-center">
                                <svg className="w-6 h-6 stroke-current text-green-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>
                                <span className="text-green-600">Name</span>
                                </div>
                                <h1 className="text-gray-600 font-light">{profileDetails.firstName} {profileDetails.lastName}</h1>
                            </div>
                            <div className="mb-3">
                                <div className="flex justify-center items-center">
                                <svg className="w-6 h-6 stroke-current text-green-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                                <span className="text-green-600">Email</span>
                                </div>
                                <h1 className="text-gray-600 font-light">{ profileDetails.email }</h1>
                            </div>
                            <div className="mb-3">
                                <div className="flex justify-center items-center">
                                <svg className="w-6 h-6 stroke-current text-green-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>
                                <span className="text-green-600">Phone Number</span>
                                </div>
                                
                                <h1 className="text-gray-600 font-light">{ profileDetails.phone }</h1>
                            </div>
                            <div className="mb-3">
                                <div className="flex justify-center items-center">
                                <svg className="w-6 h-6 stroke-current text-green-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" /></svg>
                                
                                <span className="text-green-600">Address</span>
                                </div>
                                <h1 className="text-gray-600 font-light">{ profileDetails.address }</h1>
                            </div>
                            <div>
                                <span className="text-green-600">Membership</span>
                                <h1 className="text-gray-600 font-light">{ profileDetails.membership ? "Registered" : "Not Registered" }</h1>
                            </div>
                            {profileDetails.membership ? (
                                    <div className="mt-5 md:mt-0">
                                    <PDFDownloadLink
                  document={<LibraryCard libraryCard={ libraryCard }  />}
                  fileName="myLibraryIdentityCard.pdf"
                  style={{
                    textDecoration: "none",
                    padding: "10px",
                    color: "#ffffff",
                    backgroundColor: "green",
                    border: "1px solid white",
                  
                  }}
                >
                  Download Library Card
                </PDFDownloadLink>
                </div>
                            ) : null


                            }
                            
                    </div>
                </div>

    )
}

const mapStateToProps = state => {
    return{
        profile : state.profile
    }
}

export default connect(mapStateToProps)(AboutUser)
