import React from 'react';

import { connect } from 'react-redux';
import { NavLink, Switch, Route } from 'react-router-dom'

import AboutAdmin from './AboutAdmin'
import AdminChangePassword from './AdminChangePassword'
import AdminEditAccount from './AdminEditAccount';


const AdminProfile = ({ profile, match }) => {

        const { profileDetails } = profile
        return(
                <div className="p-5 md:p-7">
            <h1 className="text-2xl text-black text-bold">My Profile</h1> 
            <hr className="text-red-400 h-7 my-5" />
            <div className="flex items-center justify-center">
            <div className="shadow-lg rounded-lg flex flex-col items-center justify-center text-center md:w-3/5 w-full">
                <div className="flex flex-col items-center justify-center">
                <img src={ profileDetails.profileImage } alt="avatar" className="w-28 h-24 rounded-full" />
                <h1 className="text-bold text-lg">{ profileDetails.firstName } { profileDetails.lastName }</h1>
                <h1>{ profileDetails.email }</h1>
                </div>
                <nav className="grid grid-cols-3 gap-1 mt-4">
                    {/* Links for routing */}
                    <NavLink to="/admin/profile/about"  activeStyle={{
    fontWeight: "bold",
    color: "green"
  }} className="text-gray-500 text-sm md:text-lg">About</NavLink>
                    <NavLink to="/admin/profile/edit-account" activeStyle={{
    fontWeight: "bold",
    color: "green"
  }} className="text-gray-500 text-sm md:text-lg">Edit Profile</NavLink>
                    <NavLink to="/admin/profile/change-password" activeStyle={{
    fontWeight: "bold",
    color: "green"
  }} className="text-gray-500 text-sm md:text-lg">Change Password</NavLink>
                    
                </nav>
                <hr className="bg-red-600 h-7"/>
                {/* Links srouting routes */}
                <Switch>
                        <Route path={`${match.path}/about`} component={ AboutAdmin  }  />
                        <Route path={`${match.path}/change-password`} component={ AdminChangePassword } />
                        <Route path={`${match.path}/edit-account`}  component={ AdminEditAccount }  />
                </Switch>
                
            </div>
            </div>
        </div>
        )
}


const mapStateToProps = state => {
        return{
                profile : state.profile
        }
}

export default connect(mapStateToProps)(AdminProfile)