import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import {store} from "./Redux/store";
import { Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./Redux/actions/authActions";
import { clearCurrentProfile, getCurrentProfile } from "./Redux/actions/profileActions";


import Nav from "./components/Nav/Nav";
import Menu from "./components/menu/Menu";
import { AdminPrivateRoute }  from './components/AdminProtectedRoute'
import { UserPrivateRoute }  from './components/UserProtectedRoute'
import { ResourcesPrivateRoute } from './components/ResourcesProtectedRoute'

import Home from "./Views/Home/Home";
import Admin from "./Views/admin/Admin";
import Contact from "./Views/Contact";
import AboutUs from "./Views/AboutUs";
import Service from "./Views/Service/Service";


import NotFound from "./Views/NotFound";
import UserProfile from './Views/UserProfile'
import UserDashboard from './Views/user/UserDashboard'

import Login from "./Views/auth/Login";
import Register from "./Views/auth/Register";

import Resources from "./Views/resource/Resources";
import Library from "./Views/resource/Library";
import DigitalCategory from "./Views/resource/DigitalCategory";
import DigitalBooks from "./Views/resource/DigitalBooks";
import PhysicalBooks from "./Views/resource/PhysicalBooks";
import LibraryCard from "./Views/user/pages/LibraryCard";
import PaymentSuccessful from "./Views/PaymentSuccessful";
import PaymentFailed from "./Views/PaymentFailed";




if (localStorage.jwtToken) {

  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  
  // store user Id to localStorage
  localStorage.setItem("userId", decoded._id);

  
  // Set  isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  

  // Check for expired token
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {

    // Logout user
    store.dispatch(logoutUser());

    // Clear current Profile
    store.dispatch(clearCurrentProfile());

    // clear 
    
    // Redirect to login
    window.location.href = "/";
  }
}

function App({ getCurrentProfile }) {
  const [menuOpen, isMenuOpen] = useState(false);

  const menuController = () => isMenuOpen(!menuOpen);

  useEffect(() => {
    const menuCheck = () => {
      if (window.innerWidth > 760) {
        isMenuOpen(false);
      }
    };
    window.addEventListener("resize", menuCheck);

    return () => window.removeEventListener("resize", menuCheck);
  }, [isMenuOpen]);
  
  

  return (
    <div className="App" id="App">
      <Nav menu={menuController} menuOpen={menuOpen} />
      <Menu isMenuOpen={menuOpen} menu={menuController} />
      <div id="page-wrap">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/user/login" component={Login} />
          <Route exact path="/user/register" component={Register} />
          <Route exact path="/resources" component={Resources} />
          <ResourcesPrivateRoute
            exact
            path="/resources/digital-books"
            component={DigitalBooks}
          />
          <ResourcesPrivateRoute
            exact
            path="/resources/physical-books"
            component={PhysicalBooks}
          />
          <ResourcesPrivateRoute path="/resources/library-resources" component={Library} />
          
          <ResourcesPrivateRoute
            exact
            path="/resources/digital-books/category"
            component={DigitalCategory}
          />
          <AdminPrivateRoute  path="/admin" component={Admin} />
          <UserPrivateRoute path="/user/profile" component={UserProfile} />
          <UserPrivateRoute path="/user-dashboard" component={UserDashboard} />
          <Route path="/library-card" component={LibraryCard} />

          <Route path="/successful-payment" component={PaymentSuccessful} />
          <Route path="/failed-payment" component={PaymentFailed} />
          
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}

export default connect(null, {getCurrentProfile})(App);
