import React, { useState } from "react";
import { connect } from 'react-redux'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

import http from '../../../utils/Axios'




const notify = (message) => toast.success( message, {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

  
  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });


const AddDigitalBook = ({  book }) => {

  const [ progress, setProgress ] = useState()
  const [ loading, setLoading ] = useState(false)

  const _id = localStorage.userId;

  


  const initialValues = {
    _id: _id,
    isbn: "",
    author: "",
    title: "",
    year: "",
    file: '',
    totalPages: "",
    category : "",
    description: "",
  };

  const validationSchema = Yup.object({
    isbn: Yup.string().required("Sorry the book ISBN is required!"),
    author: Yup.string().required("Author Name can't be empty"),
    title: Yup.string().required("Sorry the book Title is required!"),
    publisher: Yup.string(),
    totalPages: Yup.number().required(
      "Enter even if the book quantity is one (1)"
    ),
    file : Yup.mixed(),
    description: Yup.string(),
  });


// add digitalbook

const addDigitalBook = (data) => {
  setLoading(true)
  try{
    const formData = new FormData();
  formData.append("file", data.file,);
  formData.append("isbn", data.isbn);
  formData.append("description", data.description);
  formData.append("author", data.author);
  formData.append("title", data.title);
  formData.append("totalPages", data.totalPages);
  formData.append("year", data.year);
  formData.append("category", data.category);
  formData.append("_id", data._id);
      http
        .post(
          "/api/book/add/digital-book",
          formData,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept' : 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.token}`,
          },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total))
          },
        }
        )
        .then( response => {
          console.log(response)
          setLoading(false)
          response.data.status ? notify(response.data.message) : failedNotify(response.data.message)
        })

  }catch(error){
    setLoading(false)
    error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
                       
  }
}




  const onSubmit = (values) => {
    
    addDigitalBook(values);
    
  }

  return (
    <div className="grid  place-items-center">
      <ToastContainer />
      
        <div className="w-11/12  bg-white sm:w-8/12 md:w-1/2 lg:w-5/12">
        
          <div className="p-5 bg-white">
            <h1 className="text-xl font-semibold">Add Digital Book</h1>
           
            <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
             {({  setFieldValue }) => (
          <Form className="mt-6" autoComplete="off">
            <div className="flex justify-between gap-3">
              <span className="w-1/2">
                <label
                  htmlFor="isbn"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Isbn
                </label>
                <Field
                  id="isbn"
                  type="text"
                  name="isbn"
                  placeholder="Enter ISBN and press Tab"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="isbn" />
              </span>
              <span className="w-1/2">
                <label
                  htmlFor="author"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Author Name
                </label>
                <Field
                  id="author"
                  type="text"
                  name="author"
                  placeholder="Enter Author Name"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="author" />
              </span>
            </div>
            <label
              htmlFor="title"
              className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Title
            </label>
            <Field
              id="title"
              type="text"
              name="title"
              placeholder="Enter Book Title"
              className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
              required
            />
            <ErrorMessage name="title" />
            <div className="flex justify-between gap-3">
              <span className="w-1/2">
                <label
                  htmlFor="year"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Year of Publication
                </label>
                <Field
                  id="year"
                  type="text"
                  name="year"
                  placeholder="Enter Year of Publication"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="year" />
              </span>
              <span className="w-1/2">
                <label
                  htmlFor="totalPages"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Total Book Pages
                </label>
                <Field
                  id="totalPages"
                  type="number"
                  name="totalPages"
                  placeholder="Enter book Book Pages"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="totalPages" />
              </span>
            </div>
            <label
              htmlFor="category"
              className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Category
            </label>
            <Field
              id="category"
              type="text"
              name="category"
              className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
              required
              placeholder="Enter book category"
            />
            <ErrorMessage name="category" />

            <label
              htmlFor="file"
              className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Upload PDF
            </label>
            <input
              id="file"
              type="file"
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
              name="file"
              className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
              required
            />
            <ErrorMessage name="file" />
            <label
              htmlFor="description"
              className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Description
            </label>
            <Field
              id="description"
              as="textarea"
              name="description"
              placeholder="Enter Book description"
              autoComplete="description"
              className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
              required
            />
            <ErrorMessage name="description" />
            { 
              progress && (
                <div className="relative pt-1">
                  { progress === 100 ? <h1 className="text-gray-400 my-2">Uploading Completed</h1> : <h1 className="text-gray-400 my-2">Uploading in progress...</h1>}
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                    
                    <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                  </div>
                  <h1 className="text-gray-800 ">{progress} %</h1>
                </div>
              )
            }
            <button
              type="submit"
              className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-green-700 shadow-lg focus:outline-none hover:bg-green-900 hover:shadow-none"
            >
              { loading ? 'Loading...' : 'Add Book'}
            </button>
          </Form>
          )}
        </Formik>
           
            
          </div>
      
        </div>
    </div>
  );
};



const mapStateToProps = (state) => {
  return {
    book: state.book,
  };
};

export default connect(mapStateToProps)(AddDigitalBook);
