import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";


import { PersistGate } from 'redux-persist/integration/react'
import { store,  myPersiststore } from "./Redux/store";



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    
      <BrowserRouter>
     <PersistGate loading={<h1>Loading...</h1>} persistor={ myPersiststore}>   
          <App />
     </PersistGate>

      </BrowserRouter>
      
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
