import React from 'react'
import { NavLink , Route, Switch } from 'react-router-dom'
import IssuedBooks from './pages/IssuedBooks'
import UserDuedBooks from './pages/UserDuedBooks'
import UserReservedBook from './pages/UserReservedBook'



const UserDashboard = ({ match }) => {
        return(
                <div className="p-5">
                        <h1>Dashboard </h1>
                        <hr className="my-3"/>
                        <nav className="flex justify-center items-center rounded-lg border">
                                <NavLink to={`${match.path}/issued-books`} activeStyle={{
    fontWeight: "bold",
    color: "white",
    backgroundColor:"green",
    borderRadius: '10px'
    }} className="p-3">Borrowed</NavLink>
                                <NavLink to={`${match.path}/reserved-books`} activeStyle={{
    fontWeight: "bold",
    color: "white",
    backgroundColor:"green",
    borderRadius: '10px'
    }} className="p-3">Reserved</NavLink>
                                <NavLink to={`${match.path}/due-books`} activeStyle={{
    fontWeight: "bold",
    color: "white",
    backgroundColor:"green",
    borderRadius: '10px'
    }} className="p-3">Overdue</NavLink>
                        </nav>
                        <div className="py-3">
                                <Switch>
                                        <Route path={`${match.path}/issued-books`} exact component={ IssuedBooks}  />
                                        <Route path={`${match.path}/reserved-books`} exact component={UserReservedBook} />
                                        <Route path={`${match.path}/due-books`} exact component={ UserDuedBooks} />
                                </Switch>
                        </div>
                </div>
        )
}


export default UserDashboard