import React, { useState } from "react";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getCurrentProfile } from '../../../Redux/actions/profileActions'
import http from '../../../utils/Axios'
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import ProfileImageChangeComponent from "../components/ProfileImageChangeComponent";



const EditAccount = ({  getCurrentProfile, profile }) => {

    const [ loading, setIsLoading ] = useState(false)

    const { profileDetails } = profile
   // toastify success

const notify = (message) => toast.success( message, {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

  // update account

  const updateAccount = async (data ) => {
    setIsLoading(true)
    try {
      const response = await  http
      .post("/api/auth/update", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            Authorization: "Bearer " + localStorage.token,
        },
      })
      setIsLoading(false)
      getCurrentProfile()
      response.data.status ? notify(response.data.message) : failedNotify(response.data.message)
    } catch (error) {

      error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
    }
  }
  
 

  
  // updateAccountHandler 

  const initialValues = {
    _id :  profileDetails._id,
    firstName: profileDetails.firstName,
    lastName: profileDetails.lastName,
    emailAddress: profileDetails.email,
    phone: profileDetails.phone,
    address: profileDetails.address,
    
  };
  
  const onSubmit = values =>   updateAccount(values)




  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name can't be empty!"),
    lastName: Yup.string().required("Last name can't be empty"),
    emailAddress: Yup.string().email().required("sorry Email can't be empty!"),
    phone: Yup.string().required('Sorry your phone number is required'),
    address: Yup.string().required("Sorry address is required"),
    
  });
  return (
    <div >
      <ToastContainer />
      {" "}
      <div className="grid  text-left ">
        <ProfileImageChangeComponent />
        
        <div className=" py-3 px-6 bg-white sm:w-full md:w-full lg:w-full flex justify-center items-center">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form  autoComplete="off">
              <div>
                <div className="w-full">
                  <div className="flex justify-between gap-3">
                    <span className="w-1/2">
                      <label
                        htmlFor="firstName"
                        className="block text-xs font-semibold text-gray-600 uppercase mt-2"
                      >
                        First Name
                      </label>
                      <Field
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="Enter your first name"
                        className="block w-full p-2 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner "
                        required
                      />
                      <ErrorMessage name="firstName" />
                    </span>
                    <span className="w-1/2">
                      <label
                        htmlFor="lastName"
                        className="block text-xs font-semibold text-gray-600 uppercase mt-2"
                      >
                        Last Name
                      </label>
                      <Field
                        id="lastName"
                        type="text"
                        name="lastName"
                        placeholder="Enter your last Name"
                        className="block w-full p-2 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner "
                        required
                      />
                      <ErrorMessage name="lastName" />
                    </span>
                  </div>
                  <label
                    htmlFor="emailAddress"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Email Address
                  </label>
                  <Field
                    id="emailAddress"
                    type="mail"
                    name="emailAddress"
                    placeholder="Enter your email address"
                    className="block w-full p-2 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                    disabled
                  />
                  <ErrorMessage name="emailAddress" />

                  <label
                    htmlFor="phone"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Phone Number
                  </label>
                  <Field
                    id="phone"
                    type="tel"
                    name="phone"
                    placeholder="Enter your phone number"
                    className="block w-full p-2 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                  <ErrorMessage name="phone" />
                  <label
                    htmlFor="address"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Address
                  </label>
                  <Field
                    id="address"
                    type="text"
                    name="address"
                    placeholder="Enter your Home address"
                    className="block w-full p-2 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                  <ErrorMessage name="address" />
                  <button
                    type="submit"
                    className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-green-700 shadow-lg focus:outline-none hover:bg-green-900 hover:shadow-none"
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ( state) => {
  return {
  
    profile : state.profile
  };
}



export default connect(mapStateToProps, { getCurrentProfile })(EditAccount);


