import React from 'react'

import imageSuccessfull from '../assets/img/payment_success.jpg'

const PaymentFailed = () => {
    return (
        <div className="px-5 py-3">
            <div className="flex flex-col items-center justify-center">
                <div>
                    <img src={imageSuccessfull} alt="success payment" className="h-96" />
                </div>

                <div className="text-center">
                    <h1>Ops! sorry </h1>
                    <h1>Your transaction has failed due to some technical issues</h1>
                    <h1>Please try again</h1>
                    
                </div>

            </div>
        </div>
    )
}

export default PaymentFailed
