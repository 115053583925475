import React, { useEffect, useState  } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../utils/Axios'
import Spinner from '../../components/spinner/Spinner'

import DigitalBooksCategoryList from './component/DigitalBooksCategoryList';
import CategoryCard from './component/CategoryCard';



  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

const DigitalBooks = () => {


    const [ digitalBooks, setDigitalBooks ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
  const  _id = localStorage.userId

  const history = useHistory()

    useEffect(() => {
      setIsLoading(true)
      http
        .get(`/api/book/view/digital/${_id}`)
        .then((res) =>{
          setIsLoading(false)
           setDigitalBooks(res.data.data)}
           )
        .catch(error =>{
          setIsLoading(false)
          error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
         
        });

        
    },[_id]);
    return (
      <>
      {
        isLoading ? (
          <Spinner />
        ) : (
          <div className="p-3 md:p-5">
            <ToastContainer />
      
                
      <div className="md:grid grid-cols-3 gap-4  justify-center items-center text-center">
      {
       digitalBooks && digitalBooks.sort((a,b)=> a.category.localeCompare(b.category)).map((book, index) => {
               return(
               <div  key={index} onClick = { () => history.push({
                pathname: '/resources/digital-books/category',
                state: book
               })}>
                 <CategoryCard category={book} book={book.books.length} />
              </div>)
       }) 
      }
      </div>
    </div>
        )
      }
      
    </>
    );
}



export default DigitalBooks
