import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link, useHistory } from "react-router-dom";

import { logoutUser } from "../../Redux/actions/authActions";

const Nav = ({ menu, auth, logoutUser, profile }) => {
  const history = useHistory();
  const { isAuthenticated, user } = auth;
  const { profileDetails } = profile

  const [ isDropDownOpen, setIsDropDownOpen ] = useState(false)


  // handle logging out
  const handleLogOut = (e) => {
    logoutUser();
    history.push("/");
  };
  const authenticatedUser = (
    <>
      {/* component */}
<div className="flex justify-center " onClick={ () =>setIsDropDownOpen(!isDropDownOpen)}>
  {/* Dropdown */}
  <div className="relative">
    <button className="block h-12 w-12 rounded-full overflow-hidden focus:outline-none" onClick={ () => setIsDropDownOpen(!isDropDownOpen)}>
      <img className="h-full w-full object-cover" src={profileDetails && profileDetails.profileImage} alt="avatar" />
    </button>
    {/* Dropdown Body */}
    <div className={isDropDownOpen ? "absolute right-0 w-40 mt-2 py-2 bg-white border rounded shadow-xl z-50" : "hidden"}>   
      <Link to="/user/profile/about" className={ user.role === "Member" ?  "transition-colors duration-200 block px-4 py-2 text-normal text-gray-900 rounded hover:bg-green-500 hover:text-white" : "hidden" }>My profile</Link>
      <div className="py-2">
        <hr />
      </div>
      
          <Link to={user.role === "Admin" || user.role ==="Librarian" ? "/admin/dashboard/payment-history" : "/user-dashboard/issued-books" } className= "  transition-colors duration-200 block px-4 py-2 text-normal text-gray-900 rounded hover:bg-green-500 hover:text-white"  >DashBoard</Link>
      <div className="py-2">
        <hr />
      </div>
      
      
      <Link to="#" onClick={ () => handleLogOut()} className="transition-colors duration-200 block px-4 py-2 text-normal text-gray-900 rounded hover:bg-green-500 hover:text-white">    
        Logout
      </Link>
    </div>
    {/* // Dropdown Body */}
  </div>
  {/* // Dropdown */}
</div>

    </>
  );
  const guest = (
    <Link to="/user/login">
      <button className="items-center px-8  animate-bounce py-3 mt-4 ml-5 font-semibold text-white transition duration-500 ease-in-out transform bg-green-600 border rounded-lg lg:inline-flex lg:mt-px hover:border-blue hover:bg-green-700 hover:text-white focus:ring focus:outline-none">
        Sign In
        <svg
          className="hidden w-4 h-4 ml-1 lg:block "
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </button>
    </Link>
  );

  return (
    <header className="text-gray-700 px-5 bg-white border-t border-b body-font  z-10">
      <div className="container flex  justify-between px-3 justify-center py-4 md:py-5  items-center flex-row ">
        <div className="grid grid-cols-2 gap-0 justify-center items-center ">
        <Link
          className="flex items-center  mr-2 font-medium text-gray-900 title-font md:mb-0 "
          to="/"
        >
          <img
            src="https://fmcabeokuta.net/wp-content/uploads/LOGO-150x150.jpg"
            alt="logo"
            className="h-12 md:h-16"
          />
        </Link>
        <div className="lg:hidden" onClick={menu}>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </div>
        </div>
        
        <nav className="hidden lg:flex flex-wrap items-center justify-center text-base ">
          <NavLink
            to="/"
            className="mr-5 text-md font-semibold text-gray-600 lg:ml-24 hover:text-green-800 "
            
            activeStyle={{
              fontWeight: "bold",
              color: "green"
            }}
          >
            Home
          </NavLink>

          <NavLink
            to="/resources"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
            activeStyle={{
    fontWeight: "bold",
    color: "green"
  }}
          >
            Resources
          </NavLink>
          <NavLink
            to="/services"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
            activeStyle={{
    fontWeight: "bold",
    color: "green"
  }}
          >
            Services
          </NavLink>
          <NavLink
            to="/about-us"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
            activeStyle={{
              fontWeight: "bold",
              color: "green"
            }}
          >
            About
          </NavLink>
          <NavLink
            to="/contact"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
            activeStyle={{
    fontWeight: "bold",
    color: "green"
  }}
          >
            Contact
          </NavLink>
        </nav>
        <div className="  lg:flex items-center justify-center ml-auto">
          {isAuthenticated ? authenticatedUser : guest}
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile : state.profile
  };
};

export default connect(mapStateToProps, { logoutUser })(Nav);
