import React from 'react'
import { Link  } from 'react-router-dom'
import Logo from '../../assets/img/logo.png'

const Footer = () => {
    return (
<footer className=" border-t body-font bg-green-600 text-white">
<div
className=" md:grid grid-cols-2 gap-2 lg:grid-cols-4   p-8 mx-auto md:items-center lg:items-start  ">
<div className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left">
<Link to="#" className="flex flex-col items-center justify-center font-medium text-gray-900 title-font md:justify-start ">
<img src={Logo} alt="Logo" />
<p className="mt-6 text-lg text-center text-white">
              The FMCA Medical Library
             </p>
</Link>
</div>

<div className="w-full px-4 lg:w-1/3 md:w-1/2">
<h1 className="mb-3 text-sm font-semibold tracking-widest  uppercase title-font">
Company
</h1>
<nav className="mb-10 list-none">
<li>
<Link to="/about-us" className="text-sm antialiased  hover:text-gray-800">About Us</Link>
</li>
<li>
<Link to="/services" className="text-sm antialiased  hover:text-gray-800">Services</Link>
</li>
</nav>
</div>
<div className="w-full px-4 lg:w-1/3 md:w-1/2">
<h1 className="mb-3 text-sm font-semibold tracking-widest  uppercase title-font">
Support
</h1>
<nav className="mb-10 list-none">
<li>
<Link to="/contact" className="text-sm  hover:text-gray-800">Contact Us</Link>
</li>
</nav>
</div>
<div className="w-full">
<h1 className="mb-3 text-sm font-semibold tracking-widest  uppercase title-font">Address
</h1>
<nav className="mb-10 list-none">
<div className="flex flex-col">
                 <div className="pt-3">
                   <h1>Location</h1>
                   <p className=" text-sm">
                     Bisi Onabanjo Way, Idi-Aba, Abeokuta Ogun State.
                   </p>
                 </div>
                 <div className="pt-3">
                   <h1>Email</h1>
                   <a
                     href="mailto:medical-library.fmca@fmc.org"
                     className=" text-sm"
                   >
                     medical-library.fmca@fmcabeokuta.org
                   </a>
                 </div>
                 <div className="py-3">
                   <h1>Phone Numbers</h1>
                   <div className="flex flex-col">
                     {" "}
                     <a href="tel:08095947083" className="ml-3 text-sm">
                       08095947083
                     </a>
                     <a href="tel: 08095946034" className="ml-3 text-sm">
                       08095946034
                     </a>
                     <a href="tel:08033482191" className="ml-3 text-sm">
                       08033482191
                     </a>
                   </div>
                 </div>
                 </div>
               
</nav>
</div>

</div>
<div className="bg-blueGray-900">
<div className="container flex flex-col flex-wrap px-5 py-6 mx-auto sm:flex-row">
<p className="text-sm text-center text-gray-200 sm:text-left ">© The FMCA Medical Library 2021
</p>
<span className="inline-flex justify-center mt-2 sm:ml-auto sm:mt-0 sm:justify-start">
<a href="/" className="text-white hover:text-blue-500">
<svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
className="w-5 h-5" viewBox="0 0 24 24">
<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
</svg>
</a>
<a href="/" className="ml-4 text-white hover:text-blue-500">
<svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
className="w-5 h-5" viewBox="0 0 24 24">
<path
 d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">
</path>
</svg>
</a>
<a href="/" className="ml-4 text-white hover:text-blue-500">
<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
<rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
</svg>
</a>
</span>
</div>
</div>
</footer>
     
 
    );
}

export default Footer
