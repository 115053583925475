import React from 'react';




const Testimonial = () => {
      
  

    return (
      <section className="text-gray-700 body-font my-4">
        <div className="container px-8 pb-24 mx-auto  lg:px-4">
          <div className="flex flex-wrap -m-4">
            <div
              className="p-8 mx-auto mb-6 lg:w-1/3 lg:mb-0 "
              
            >
              <div className="h-full p-4 text-left border rounded-xl shadow-2xl">
                <p
                  className="text-base font-medium leading-relaxed text-gray-700"
                  
                >
                  "I have been a consistent user of FMCA Medical Library since
                  2002 as a resident & later a consultant. It has been an
                  unlimited source of resources & materials to support medical
                  training & research. I cannot easily forget my first contact
                  with HINARI in 2005 through this Library. FMCA Medical Library
                  has indeed been a source of support for knowledge for those
                  who have availed themselves of the opportunity domiciled in
                  her operation."
                </p>
                <div className="flex flex-wrap items-center mt-6">
                  <img
                    alt="testimonial"
                    className="inline-block object-cover object-center w-16 h-16 mb-4 rounded-full bg-4gray-100"
                    src="https://dummyimage.com/302x302/94a3b8/ffffff"
                  />
                </div>
                <span className="flex flex-col flex-grow ">
                  <span className="font-medium text-gray-900 title-font">
                    - Dr Adebiyi W .A.
                  </span>
                  <span className="text-sm text-gray-500 uppercase">
                    Consultant Family Physician
                  </span>
                  <span className="font-medium text-gray-500 uppercase">
                    FMC, Abeokuta
                  </span>
                </span>
              </div>
            </div>
           {/* two */}
           <div
              className="p-8 mx-auto mb-6 lg:w-1/3 lg:mb-0 "
              
            >
              <div className="h-full p-4 text-left border rounded-xl shadow-2xl">
                <p
                  className="text-base font-medium leading-relaxed text-gray-700"
                  
                >
                  "This FMCA central Library as an indispensable asset of this institution has been of utmost benefit to me as it provides 
                  a conducive environment in times that i need to study & the services rendered are satisfactory."
                </p>
                <div className="flex flex-wrap items-center mt-6">
                  <img
                    alt="testimonial"
                    className="inline-block object-cover object-center w-16 h-16 mb-4 rounded-full bg-4gray-100"
                    src="https://dummyimage.com/302x302/94a3b8/ffffff"
                  />
                </div>
                <span className="flex flex-col flex-grow ">
                  <span className="font-medium text-gray-900 title-font">
                    - Dr A E Genesis (Registrar)
                  </span>
                  <span className="text-sm text-gray-500 uppercase">
                    Surgery Department
                  </span>
                </span>
              </div>
            </div>
           {/* three */}
           <div
              className="p-8 mx-auto mb-6 lg:w-1/3 lg:mb-0 "
              
            >
              <div className="h-full p-4 text-left border rounded-xl shadow-2xl">
                <p
                  className="text-base font-medium leading-relaxed text-gray-700"
                  
                >
                  "FMCA Medical Library is a disciplined area to study with availability of medical books, journals &
                  internet facility which aid researches. 
                  I remember medline; the first software made available to me as a Library user which was very helpful. It is really commendable that the staffs
                  are disciplined, friendly & maintain quiet environment conducive for learning.
                  Thanks to the Medical Library for sustaining learning culture through the use of its collections."
                </p>
                <div className="flex flex-wrap items-center mt-6">
                  <img
                    alt="testimonial"
                    className="inline-block object-cover object-center w-16 h-16 mb-4 rounded-full bg-4gray-100"
                    src="https://dummyimage.com/302x302/94a3b8/ffffff"
                  />
                </div>
                <span className="flex flex-col flex-grow ">
                  <span className="font-medium text-gray-900 title-font">
                    - Dr Odunfa Oluwagbenga Olufemi
                  </span>
                  <span className="text-sm text-gray-500 uppercase">
                    Deputy Director of Pharmaceutical Services/HOD Pharmacy
                  </span>
                  <span className="font-medium text-gray-500 uppercase">
                    FMC, Abeokuta
                  </span>
                </span>
              </div>
            </div>
           {/* four */}
           <div
              className="p-8 mx-auto mb-6 lg:w-1/3 lg:mb-0 "
              
            >
              <div className="h-full p-4 text-left border rounded-xl shadow-2xl">
                <p
                  className="text-base font-medium leading-relaxed text-gray-700"
                  
                >
                  "As a doctor undergoing a residency training program in Federal Medical training 
                  program in Federal Medical Center Abeokuta, 
                  I find it very laudable that our institution has a Medical Library with internet facilities
                  located in a conducive & serene environment for reading and other academic purposes."
                </p>
                <div className="flex flex-wrap items-center mt-6">
                  <img
                    alt="testimonial"
                    className="inline-block object-cover object-center w-16 h-16 mb-4 rounded-full bg-4gray-100"
                    src="https://dummyimage.com/302x302/94a3b8/ffffff"
                  />
                </div>
                <span className="flex flex-col flex-grow ">
                  <span className="font-medium text-gray-900 title-font">
                    - Dr Virginia Guvoeke
                  </span>
                  <span className="text-sm text-gray-500 uppercase">
                    Senior Registrar, Paediatrics Department
                  </span>
                  
                </span>
              </div>
            </div>
           
           
          </div>
        </div>
      </section>
    );
}


export default Testimonial