import React, { useEffect, useState} from "react";

import { connect } from "react-redux";
import Menu from "./AdminSideMenu";


// import Header from './Header'
import { Route, Switch } from "react-router-dom";
import AddUser from "./pages/AddUser";
import AddLibrarian from "./pages/AddLibrarian";
import AddBook from "./pages/AddBook";
import AddDigitalBooks from "./pages/AddDigitalBooks";
import AddResources from "./pages/AddResources";

import UserListPage from "./pages/UserListPage";
import LibrarianListPage from "./pages/LibrarianListPage";
import ViewIssuedBooks from "./pages/ViewIssuedBooks";
import ReservedBooks from "./pages/ReservedBooks";

import AdminDashboard from './pages/AdminDashboard'


import NotFound from "../NotFound";
import ListOfBooks from "./pages/ListOfPhysicalBooks";
import AdminProfile from "./pages/AdminProfile";
import OverdueBooks from "./pages/OverdueBooks";
import ListOfResources from "./pages/ListOfResources";
import ListOfDigitalBooks from "./pages/ListOfDigitalBooks";

const Admin = ({ match }) => {
  
  const [menuOpen, isMenuOpen] = useState(false);

  const menuController = () => {
    isMenuOpen(!menuOpen)
   
  };

  useEffect(() => {
    const menuCheck = () => {
      if (window.innerWidth > 760) {
        isMenuOpen(false);
      }
    };
    window.addEventListener("resize", menuCheck);

    return () => window.removeEventListener("resize", menuCheck);
  }, [isMenuOpen]);



  return (
    <div className=" bg-white">
      
      <div className="md:flex">
        <div >
        <Menu   isTheMenuOpen={menuOpen}  isMenuOpen={isMenuOpen}/>
        </div>

        <div className="w-full">
          <div onClick={() => menuController()}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" /></svg>
          </div>
          <Switch>
            <Route path={`${match.url}/add-user`} component={AddUser} />
            <Route
              path={`${match.url}/add-librarian`}
              component={AddLibrarian}
            />
            <Route path={`${match.url}/add-book`} component={AddBook} />
            <Route
              path={`${match.url}/add-digitalbook`}
              component={AddDigitalBooks}
            />
            <Route 
              path={`${match.url}/add-new-resources`}
              component={AddResources}
            />
            <Route 
              path={`${match.url}/list-of-resources`}
              component={ListOfResources}
            />
            
            <Route path={`${match.url}/all-users`} component={UserListPage} />
            <Route
              path={`${match.url}/all-librarians`}
              component={LibrarianListPage}
            />
            <Route
              path={`${match.url}/view-all-issued-books`}
              component={ViewIssuedBooks}
            />
            <Route
              path={`${match.url}/view-physical-books`}
              component={ListOfBooks}
            />
            <Route
              path={`${match.url}/view-digital-books`}
              component={ListOfDigitalBooks}
            />

            
            <Route
              path={`${match.url}/view-all-reserved-books`}
              component={ReservedBooks}
            />
            <Route
              path={`${match.url}/view-all-overdue-books`}
              component={OverdueBooks}
            />
            <Route
              path={`${match.url}/dashboard`}
              component={AdminDashboard}
            />
            <Route path={`${match.url}/profile`} component={AdminProfile} />
            <Route path={`${match.url}/*`} component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Admin);
