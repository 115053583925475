import React from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
  } from "@react-pdf/renderer";
import logoImage from '../../../assets/img/logoImage.jpg'

const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      padding : 15,
      border: 'black 2px solid'
    },
    bodyCard :{
        width: "80%",
        
    },
    logo:{
        height: 50,
        width: 50,
    },
    detailsPart : {
        display: "flex",
        flexDirection : "row",
        
    },
    cardDetails :{
        display: "flex",
        flexDirection : "row",
        marginVertical: 5,
        fontSize: 12,
        
    },
    
    
    
  
    image: {
      height: 100,
      width: 130,
      backgroundColor: "grey",
      marginLeft: 20
    },
    
    cardHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: 'center',
      marginVertical: 5,
    },
    
  });


const LibraryCard = ({ libraryCard }) => {
  
    return (
        <Document>
        <Page style={styles.page}>
          <View style={styles.bodyCard}>
              <View style={styles.cardHeader}>
                  <Image source={logoImage} style={styles.logo} />
                  <Text>FMC Library IDENTITY CARD</Text>
              </View>
              <View style={ styles.detailsPart}>
                  <View>
                    <View style={styles.cardDetails}>
                      <Text>Name: </Text>
                      <Text>{ libraryCard.fullName  }</Text>
                    </View>
                    
                    <View style={styles.cardDetails}>
                      <Text>Identity Number: </Text>
                      <Text>{ libraryCard.libraryId }</Text>
                    </View>
                    
                    <View style={styles.cardDetails}>
                      <Text>Phone: </Text>
                      <Text>{libraryCard.phone }</Text>
                    </View>
                    
                    
                    
                    <View style={styles.cardDetails}>
                      <Text>Expiry Date: </Text>
                      <Text>{ libraryCard.expiredDate }</Text>
                    </View>
                    <View style={styles.cardDetails}>
                      <Text>Address: </Text>
                      <Text>{libraryCard.address}</Text>
                    </View>
                </View>
                  <View style={styles.image}>
                      <Image src={ libraryCard.profileImage } />
                  </View>
              </View>
              
          </View>
        </Page>
      </Document>
    )
}




export default LibraryCard
