// import axios from 'axios';
import http from '../../utils/Axios'

import {
  ADD_LIBRARIAN,
  ADD_USER,
  MESSAGE,
  DELETE_USER,
  DELETE_LIBRARIAN,
  UPDATE_PROFILE,
  GET_ERRORS,
} from "./types";



const token = localStorage.jwtToken

const addUser = () => {
    return{
        type : ADD_USER
    }
}

const userDelete = () => {
    return{
        type : DELETE_USER
    }
}
const successMessage = () => {
    return{
        type : MESSAGE
    }
}


const addLibrarian = () => {
  return {
    type: ADD_LIBRARIAN,
  };
};

const librarianDelete = () => {
  return {
    type: DELETE_LIBRARIAN,
  };
};


const accountUpdate = () => {
  return {
    type: UPDATE_PROFILE,
  };
}


// add new user

export const addNewUser = data => dispatch => {
    try {
      dispatch(addUser());
        http
          .post("/api/auth/register/member", data, {
            headers: {
              'Content-Type': 'application/json',
              'Accept' : 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
          .then((res) => {
              console.log(res.data)
            dispatch(successMessage());
          })
          .catch((err) =>{
          console.log(err)
            dispatch({
              type: GET_ERRORS,
              payload: err.response.data.message,
            })}
          );
    } catch (err) {
        console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.message,
      })
    }
}


// addNewLibrarian


export const addNewLibrarian = (data) => (dispatch) => {
  try {
    dispatch(addLibrarian());
    http
      .post("/api/auth/register/librarian", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          Authorization : "Bearer " + token
        },
      })
      .then((res) => {
        console.log(res)
        dispatch(successMessage())
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message
        })
      );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.message
    })
  }
};



// delete Librarian


export const deleteLibrarian = (data) => (dispatch) => {
  try {
    dispatch(librarianDelete());
    http
      .post("/api/auth/remove/librarian", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        dispatch(successMessage());
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        })
      );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.message,
    });
  }
};

