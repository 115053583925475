import React, { useState, useEffect  } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import http from '../../utils/Axios'

import {
  ModalProvider,
  Modal,
  useModal,
  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";
import Spinner from '../../components/spinner/Spinner'

const Resources = ({ auth, profile } ) => {

  const [ loading, setLoading ] = useState(false);
  const [ resources, setResources ] = useState([])
  const _id =localStorage.userId

  const history = useHistory()

  const { isModalOpen, openModal, closeModal } = useModal();

  const { membership } = profile.profileDetails
  const { isAuthenticated } = auth

  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });


    const fetchResourcesList = async () => {
      setLoading(true)
      try {
              const response = await http.get('/api/resources')
              setLoading(false)
              setResources(response.data.data)
      } catch (error) {
              
              error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
              setLoading(false)
      }
}

  const handleMembershipForLibrary = () => {
    if(membership){
       history.push("/resources/library-resources")
    }else{
      return openModal()
    }

    
  }

  const handleMembershipForHinary = () => {
    if(membership){
      const newWindow = window.open('https://www.who.int/hinari/en/','_blank','noopener,noreferrer');
      if(newWindow) newWindow.opener = null;
    }else{
      openModal()
    }
     
  }

  const handleOnClickedResources = (url)=> {
      const newWindow = window.open(url,'_blank','noopener,noreferrer');
      if(newWindow) newWindow.opener = null;
  }

  const handlePaymentRequest = () => {
    http.get(`/api/payment/init/${_id}`)
    .then(res => {
      if(res.data.status){
        window.location.href= res.data.data.url
      }
    })
    .catch(error => {
      error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
     
    })
  }

  useEffect(() => {
    fetchResourcesList()
    
  }, [])

    return (
      <div>
        { loading ? ( <Spinner /> ) 
        : (
          <>
        <ToastContainer />
        <ModalProvider>
      <div className="p-2 md:p-5">
        <h1 className="text-lg md:text-2xl text-center text-gray-600 mb-4">
          Library Database
        </h1>
        <div className="md:grid grid-cols-2 gap-4  justify-center items-center text-center">
         
          <div onClick={()=> handleMembershipForLibrary() }>
            <div className="p-5 bg-gradient-to-r from-green-900 via-green-700 to-green-500  text-white mt-4 md:ml-3 h-44 w-30 rounded-lg flex flex-col items-center justify-center shadow-2xl hover:bg-green-900 cursor-pointer ">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                />
              </svg>

              <h1>Library Database A-Z</h1>
            </div>
          </div>
          <div onClick={ () => handleMembershipForHinary()} >
            <div className="p-5 bg-gradient-to-r from-green-900 via-green-700 to-green-500  text-white mt-4 md:ml-3 h-44 w-30 rounded-lg flex flex-col items-center justify-center shadow-2xl hover:bg-green-900 cursor-pointer ">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" /></svg>
              <h1>Hinari</h1>
            </div>
          </div>
      
        </div>

        <div>
          <h1 className="text-lg md:text-2xl text-gray-600 text-center my-4">
            Free Resources
          </h1>
          <div className="md:grid grid-cols-4 gap-4  justify-center items-center text-center">
            { resources ? resources.map((resource) => {
              return (
                  <div key={ resource._id} onClick={ ()=> handleOnClickedResources(resource.url) } className="p-2 bg-gradient-to-r from-green-900 via-green-700 to-green-500  text-white mt-4 md:ml-3 h-44 w-30 rounded-lg flex flex-col items-center justify-center shadow-2xl hover:bg-green-900 cursor-pointer ">
                    <svg
                      className="w-10 h-10"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                    <h1>{resource.title}</h1>
                  </div>
              );
            }) : (
              <div>
                                                                                Ops! No resources Available
                                                                        </div>
            )
          }
          </div>
        </div>
      </div>
      <Modal
              id="any-unique-identifier"
              isOpen={isModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                
                  <>
                    
                    <h1 className="my-5 text-center text-bold text-black text-xl">
                      Ops!
                    </h1>
                    <h1 className="my-5 text-center text-bold text-gray-600 text-lg">
                      Seems you are not logged in or you are not a registered member of FMCA Library
                    </h1>
                    {isAuthenticated ? (
                      <button className="bg-green-500 text-white py-2 px-4 shadow-lg rounded-lg my-5" onClick={() => handlePaymentRequest()}>
                      Make Payment
                    </button>
                    ) : null

                    }
                    
                    
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg"
                    >
                      close
                    </button>
                  </>
                
              </div>
            </Modal>
      </ModalProvider>
      </>
        )
}
      </div>
    );
}

const mapStateToProps = state => {
  return{
    auth : state.auth,
    profile : state.profile
  }
}

export default connect(mapStateToProps)(Resources)
