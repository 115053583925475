import React, { useEffect, useState } from 'react'
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ModalProvider, Modal, ModalTransition } from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import http from '../../../utils/Axios'

import DataTable from 'react-data-table-component';




const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="p-2 border border-green-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Title and Author" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);



const ListOfPhysicalBooks = () => {
    const [ booksData, setBooksData ] = useState([])
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [ isEditBookModalOpen, setIsEditBookModalOpen ] = useState(false)
    const [ bookBorrowModal, setBookBorrowModal ] = useState(false)
    const [ bookId, setBookId ] = useState(null)
    const [loading, setLoading] = useState(false);
    const [ bookData, setBookData ] = useState({})

    // toastify

    const notify = (message) => toast.success( message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

      const failedNotify = (message) => toast.error( message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    // fetch list of books
    const fetchListOfBooks = async () => {
      setLoading(true)
      try{
        
        const response = await http.get(`/api/book/view/libraryBooks/${localStorage.userId}`)
        if(response){
          
        setBooksData(response.data.data)
        setLoading(false)
        }
      }catch(error){
        
        error.message === 'Network Error' ? failedNotify('Network Error') : failedNotify('Connection Timeout')
        setLoading(false)
      }
    }

    // delete book

    const deleteBook = async (data) => {
      setLoading(true)
      try{
        const response = await http.post('/api/book/delete-book', data)
        console.log(response)
        if(response.data.status === 0){
          setLoading(false)
          fetchListOfBooks()
          setIsDeleteModalOpen(false)
          notify(response.data.message)
        }
        
      }catch(error){
        error.message === 'Network Error' ? failedNotify('Network Error') : failedNotify('Connection Timeout')
        setLoading(false)
      }
    }

    // update physical books

    const updatePhysicalBook = async (data ) => {
      setLoading(true)
      try {
        const response = await http.post('/api/book/update-physical', data)
        console.log(response)
        if(response.data.status){
          setLoading(false)
          fetchListOfBooks()
          setIsEditBookModalOpen(false)
          notify(response.data.message)
        }
        if( !response.data.message){
          setLoading(false)
          setIsEditBookModalOpen(false)
          failedNotify(response.data.message)
        } 
        
      } catch (error) {
        error.message === 'Network Error' ? failedNotify('Network Error') : failedNotify('Connection Timeout')
        setLoading(false)
        setIsEditBookModalOpen(false)
      }
    }

    //failedNotify

    // borrowbook 

    const borrowBook = async ( data ) => {
      setLoading(true)
      
      try {
        const newData = {
          _id : localStorage.userId,
          email : data.email,
          bookId : data.bookId
        }
        console.log(`newData ${newData}`)
       const response = await http
        .post("/api/book/librarian-issue-book",newData,{
          headers: {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
          },
        })
        
        setLoading(false)
        setBookBorrowModal(false)
        response.data.status ? notify(response.data.message) : failedNotify(response.data.message)
        
      } catch (error) {
        console.log(error)
      }
    }
    
    useEffect(() =>{
      fetchListOfBooks()
  }, [])
 
  


const columns = [
  {
    name: 'Isbn',
    selector: 'isbn',
    sortable: false,
  },
  {
    name: 'Title',
    selector: 'title',
    sortable: true,
    style:{
      paddingTop: '10px',
      paddingBottom: '10px',
      width: '500px'
    },
    cell: booksData => <div > { booksData.title } </div>,
  },
  {
    name: 'Author',
    selector: 'author',
    sortable: true,
    
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    
  },
  {
    name: 'Quantity',
    selector: 'quantity',
    
  },
  {
    name : 'Action',
    button : true,
    cell : booksData => <div onClick={() => {
      setBookId(booksData._id)
      setBookBorrowModal(true)}} className="bg-green-500 text-white px-4 py-2 rounded-md shadow-lg cursor-pointer"> Borrow</div>
  },
    {
    name: 'Edit Book',
    button: true,
    cell:  booksData =><svg   onClick={() => {
      setBookData(booksData)
      setIsEditBookModalOpen(true)}} className="w-6 h-6 cursor-pointer stroke-current text-blue-500 shadow-lg" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>
  },
  {
    name: 'Delete Book',
    button: true,
    cell: booksData => <svg onClick={() => {
      setBookId(booksData._id)
      setIsDeleteModalOpen(true)}} className="w-6 h-6 cursor-pointer stroke-current text-red-500 shadow-lg" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>,
  },
];

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headRow: {
      style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderColor: 'red'
    },
  },
  headCells: {
    style: {
      backgroundColor: 'green'
    },
  },
  cells: {
    style: {
      
    },
  },
};
const [filterText, setFilterText] = React.useState('');

const searchInputField = (rows) => {
  if(!rows) return []
  return rows.filter(
    (row) =>
      row.title.toLowerCase().includes(filterText.toLowerCase()) ||
      row.author.toLowerCase().includes(filterText.toLowerCase())
  );
};
console.log(`booksdata ${bookData}`)


// book edit initialValues
const initialBookEditValues = {
  _id : localStorage.userId,
  bookId: bookData._id,
  isbn : bookData.isbn,
  author: bookData.author,
  title: bookData.title,
  year: bookData.year,
  category : bookData.category,
  totalPages : bookData.totalPages,
  quantity : bookData.quantity,
  description : bookData.description
};

// book issue initialValues
const initialBookIssueValues = {
  bookId: bookId,
  email: '',
};

// book edit submit function
const updatePhysicalBookHandler = async (values, { resetForm }) => {
    await updatePhysicalBook(values)
    setIsEditBookModalOpen(false)
      resetForm()
    
  }

  console.log(bookId)

  // book issue function

  const onSubmit = async (values, { resetForm}) => {
     await borrowBook(values)
     
       resetForm();

    console.log(values)
  }

  // book edit validation function
const validationSchema = Yup.object({
  isbn: Yup.string().required("Sorry the book ISBN is required!"),
  author: Yup.string().required("Author's name is required"),
  title: Yup.string().required("Sorry the book Title is required!"),
  year : Yup.string(),
 totalPages : Yup.string().required('Give the total number of pages'),
  quantity: Yup.number().required("Enter even if the book quantiy is one (1)"),
  description: Yup.string(),
  category : Yup.string().required('Please enter the category')
});

// book borrow validation function 

const bookBorrowValidationSchema = Yup.object({
  bookId: Yup.string().required('Sorry the Book ID is required'),
  email : Yup.string().email("Sorry borrower's email address is required")
})
console.log(bookData)

    return (
      <>
      
      <ToastContainer />
      <ModalProvider>
        <div className="px-5">
          <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
      <DataTable
        title="Library Physical Books"
        columns={columns}
        data={searchInputField(booksData)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
      </div>
      {/* book delete modal */}
      <Modal
              id="any-unique-identifier"
              isOpen={isDeleteModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to delete this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsDeleteModalOpen(false);
                    }}
                    className="bg-red-500 cursor-pointer text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={  () => {
                       deleteBook({ 
                        _id : localStorage.userId,
                        bookId,
                        bookType : 'physical'
                      })
                       
                    }}
                    className="bg-blue-500 cursor-pointer text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    { loading ? 'Processing' : 'Proceed'}
                  </button>
                </div>
              </div>
            </Modal>

            {/* physical book edit modal */}
            <Modal
              id="any-unique-identifier"
              isOpen={isEditBookModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
              <div className="p-5 bg-white">
            <h1 className="text-xl font-semibold">Edit Physical Book</h1>
            <Formik
              initialValues={initialBookEditValues}
              validationSchema={validationSchema}
              onSubmit={updatePhysicalBookHandler}
            >
              <Form className="mt-6" autoComplete="off">
                <div className="flex justify-between gap-3">
                  <span className="w-1/2">
                    <label
                      htmlFor="isbn"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      Isbn
                    </label>
                    <Field
                      id="isbn"
                      type="text"
                      name="isbn"
                      placeholder="Enter ISBN and press Tab"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="isbn" />
                  </span>
                  <span className="w-1/2">
                    <label
                      htmlFor="author"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      Author Name
                    </label>
                    <Field
                      id="author"
                      type="text"
                      name="author"
                      placeholder="Enter Author Name"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="author" />
                  </span>
                </div>
                <label
                  htmlFor="title"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Book Title
                </label>
                <Field
                  id="title"
                  type="text"
                  name="title"
                  placeholder="Enter Book Title"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="title" />
                <div className="flex justify-between gap-3">
                  <span className="w-1/2">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      Category
                    </label>
                    <Field
                      id="category"
                      type="text"
                      name="category"
                      placeholder="Enter Category"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="category" />
                  </span>
                  <span className="w-1/2">
                    <label
                      htmlFor="year"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      year
                    </label>
                    <Field
                      id="year"
                      type="text"
                      name="year"
                      placeholder="Enter published year"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="year" />
                  </span>
                </div>

                <label
                  htmlFor="totalPages"
                  className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                >
                  Total Pages
                </label>
                <Field
                  id="totalPages"
                  type="text"
                  name="totalPages"
                  placeholder="Enter total number of pages of book"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="totalPages" />

                <label
                  htmlFor="title"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Quantity
                </label>
                <Field
                  id="quantity"
                  type="number"
                  name="quantity"
                  placeholder="Enter Quantity of Book"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="quantity" />
                <label
                  htmlFor="title"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Description
                </label>
                <Field
                  id="description"
                  as="textarea"
                  name="description"
                  placeholder="Enter Book description"
                  autoComplete="description"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="description" />
                
                <div>
                  <button
                    onClick={() => {
                      setIsEditBookModalOpen(false);
                    }}
                    className="bg-red-500 cursor-pointer text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    type="submit"   className="bg-blue-500 cursor-pointer text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    { loading ? 'Processing' : 'Proceed'}
                  </button>
                </div>
              </Form>
            </Formik>
          
                
              </div>
              </div>
            </Modal>
            {/* borrow issue modal */}
            <Modal
              id="any-unique-identifier"
              isOpen={bookBorrowModal}
              transition={ModalTransition.BOTTOM_UP}
            >
             <div className="w-full">
          <h1 className="text-xl font-semibold">Issue Book</h1>
          <Formik
            initialValues={initialBookIssueValues}
            validationSchema={bookBorrowValidationSchema}
            onSubmit={onSubmit}
          >
            <Form className="mt-6" autoComplete="off">
              <label
                htmlFor="bookId"
                className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
              >
                Book Title
              </label>
              <Field
                id="bookId"
                type="text"
                name="bookId"
                placeholder="Enter Book Title"
                className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                required
              />
              <ErrorMessage name="bookId" />

              <label
                htmlFor="email"
                className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
              >
                Borrower's email
              </label>
              <Field
                id="email"
                type="text"
                name="email"
                placeholder="Enter borrower's Email"
                className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                required
              />
              <ErrorMessage name="email" />
              <div className="flex justify-between gap-3">
                <span className="w-1/2">
                <div
                onClick={() => setBookBorrowModal(false)}
                className="w-full text-center  cursor-pointer py-3 mt-6 font-medium tracking-widest text-white rounded-lg uppercase bg-red-500 shadow-lg focus:outline-none hover:bg-red-500 hover:shadow-none"
              >
                Cancel
              </div>
                </span>
                <span className="w-1/2">
                <button
                type='submit'
                className="w-full py-3 mt-6 font-medium cursor-pointer tracking-widest text-white rounded-lg uppercase bg-green-500 shadow-lg focus:outline-none hover:bg-green-700 hover:shadow-none"
              >
                { loading ? 'Issuing book...' : 'Issue Book'}
              </button>
                </span>
              </div>
              
            </Form>
          </Formik>
        </div>
        
            </Modal>
      </ModalProvider>
      </>
    )
  }
;

export default ListOfPhysicalBooks