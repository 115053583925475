import React, { useEffect, useState} from 'react'

import http from '../../../utils/Axios'

import DataTable from 'react-data-table-component';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="p-2 border border-green-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Title and Author" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);


const OverdueBooks = () => {


        const _id = localStorage.userId

        const [ overdueBooks, setOverdueBooks ] = useState([])
        const [ loading, setIsLoading ] = useState(false)


  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });


        const fetchOverdueBooks = async() => {
                setIsLoading(true)
                try {
                        const response = await http.get(`/api/book/overdue-books/${_id}`) 
                        console.log(response)
                        setOverdueBooks(response.data.data)
                        setIsLoading(false)
                 } catch (error) {
                         
            error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
     
            setIsLoading(false)
                 }
                
        }
        useEffect(() => {
                fetchOverdueBooks()
        }, [])

        const columns = [
                {
                  name: 'Name',
                  cell : issuedBooks => <div>{ issuedBooks.borrowedBy.firstName} {issuedBooks.borrowedBy.lastName}</div>,
                  sortable: false,
                },
                {
                  name: 'Email',
                  selector: 'email',
                  sortable: true,
                  // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
                  cell: issuedBooks => <div>{ issuedBooks.borrowedBy.email }</div>
                },
                {
                  name: 'Address',
                  cell : issuedBooks => <div>{ issuedBooks.borrowedBy.address}</div>,
                  sortable: true,
                  
                },
                {
                  name: 'Book',
                  style:{
                    padding: '10px',
                    width: '120px'
                  },
                  cell : issuedBooks => <div className="w-full">{ issuedBooks.issuedBookId.title }</div>,
                  sortable: true,
                  
                },
                {
                  name: 'Status',
                  cell : issuedBooks => <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-green-800">{issuedBooks.borrowStatus}</div>
                  
                },
                  
              ];
            
              const customStyles = {
                
                headRow: {
                    style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderColor: 'red'
                  },
                },
                headCells: {
                  style: {
                    backgroundColor: 'green'
                  },
                },
                cells: {
                  style: {
                    
                  },
                },
              };
            
            
            const [filterText, setFilterText] = React.useState('');
            
            const searchInputField = (rows) => {
              if(!rows) return []
              return rows.filter(
                (row) =>
                  row.borrowedBy.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
                  row.borrowedBy.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
                  row.borrowedBy.email.toLowerCase().includes(filterText.toLowerCase()) ||
                  row.issuedBookId.title.toLowerCase().includes(filterText.toLowerCase())
              );
            };
            
        return (
                <div className="px-5">
                  <ToastContainer />
       <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
      <DataTable
        title="Overdue Books"
        columns={columns}
        data={searchInputField(overdueBooks)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
      
    </div>
        )
}

export default OverdueBooks
