import React from "react";

import { connect } from 'react-redux'

import Spinner from "../../../components/spinner/Spinner";


import {
  ModalProvider,
  Modal,
  useModal,
  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";
import CheckSvg from "../../../assets/img/checked.png";

import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

import { addPhysicalBook }  from '../../../Redux/actions/bookActions'

const AddBook = ( { addPhysicalBook, book, auth }) => {

  const _id = localStorage.userId

   const { isModalOpen, openModal, closeModal } = useModal();

  const initialValues = {
    _id : _id,
    isbn : '',
      author: '',
      title: '',
    year: '',
    category : '',
    totalPages : '',
      
    quantity : '',
    description : ''
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values)
    openModal()
      addPhysicalBook(values)
        resetForm();
      
    }
  const validationSchema = Yup.object({
    isbn: Yup.string().required("Sorry the book ISBN is required!"),
    author: Yup.string().required("Author's name is required"),
    title: Yup.string().required("Sorry the book Title is required!"),
    year : Yup.string(),
   totalPages : Yup.string().required('Give the total number of pages'),
    quantity: Yup.number().required("Enter even if the book quantiy is one (1)"),
    description: Yup.string(),
    category : Yup.string().required('Please enter the category')
  });
  return (
    <div className="grid  place-items-center">
      <ModalProvider>
        <div className="w-11/12  bg-white sm:w-8/12 md:w-1/2 lg:w-5/12">
        {book.loading ? (
          <Spinner />
        ) : (
          <div className="p-5 bg-white">
            <h1 className="text-xl font-semibold">Add Book</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="mt-6" autoComplete="off">
                <div className="flex justify-between gap-3">
                  <span className="w-1/2">
                    <label
                      htmlFor="isbn"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      Isbn
                    </label>
                    <Field
                      id="isbn"
                      type="text"
                      name="isbn"
                      placeholder="Enter ISBN and press Tab"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="isbn" />
                  </span>
                  <span className="w-1/2">
                    <label
                      htmlFor="author"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      Author Name
                    </label>
                    <Field
                      id="author"
                      type="text"
                      name="author"
                      placeholder="Enter Author Name"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="author" />
                  </span>
                </div>
                <label
                  htmlFor="title"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Book Title
                </label>
                <Field
                  id="title"
                  type="text"
                  name="title"
                  placeholder="Enter Book Title"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="title" />
                <div className="flex justify-between gap-3">
                  <span className="w-1/2">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      Category
                    </label>
                    <Field
                      id="category"
                      type="text"
                      name="category"
                      placeholder="Enter Category"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="category" />
                  </span>
                  <span className="w-1/2">
                    <label
                      htmlFor="year"
                      className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                    >
                      year
                    </label>
                    <Field
                      id="year"
                      type="text"
                      name="year"
                      placeholder="Enter published year"
                      className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                      required
                    />
                    <ErrorMessage name="year" />
                  </span>
                </div>

                <label
                  htmlFor="totalPages"
                  className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                >
                  Total Pages
                </label>
                <Field
                  id="totalPages"
                  type="text"
                  name="totalPages"
                  placeholder="Enter total number of pages of book"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="totalPages" />

                <label
                  htmlFor="title"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Quantity
                </label>
                <Field
                  id="quantity"
                  type="number"
                  name="quantity"
                  placeholder="Enter Quantity of Book"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="quantity" />
                <label
                  htmlFor="title"
                  className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                >
                  Description
                </label>
                <Field
                  id="description"
                  as="textarea"
                  name="description"
                  placeholder="Enter Book description"
                  autoComplete="description"
                  className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                  required
                />
                <ErrorMessage name="description" />
                <button
                  type="submit"
                  className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-green-700 shadow-lg focus:outline-none hover:bg-green-900 hover:shadow-none"
                >
                  Add Book
                </button>
              </Form>
            </Formik>
            <Modal
              id="any-unique-identifier"
              isOpen={isModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                {!book.error ? (
                  <>
                    {" "}
                    <div>
                      <img src={CheckSvg} alt="success " />
                    </div>
                    <h1 className="my-5 text-center">
                     Book Added Successfully
                    </h1>
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      className="bg-red-700 text-white py-2 px-4 shadow-lg rounded-lg cursor-pointer"
                    >
                      close
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <div></div>
                    <h1 className="my-5 text-center">{book.error}</h1>
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      className="bg-red-700 text-white py-2 px-4 shadow-lg rounded-lg cursor-pointer"
                    >
                      close
                    </button>
                  </>
                )}
              </div>
            </Modal>
          </div>
        )}
        </div>
      </ModalProvider>
    </div>
  );
};
const mapStateToProps = state => {
  return{
    auth : state.auth,
    book : state.book
  }
}


export default connect(mapStateToProps, { addPhysicalBook })(AddBook);
