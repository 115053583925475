import React from 'react'
import { connect } from 'react-redux'

import { logoutUser } from "../../Redux/actions/authActions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
 
  faBookMedical,
  faList,
  faUsers,
  faUser,
  faTachometerAlt

} from "@fortawesome/free-solid-svg-icons";


import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import { useHistory } from "react-router-dom";




const AdminSideMenu = (props) => {
  const handleResourcesLink = link => {
    const newWindow = window.open(link,'_blank','noopener,noreferrer');
      if(newWindow) newWindow.opener = null;
  }
  const { profile,auth, logoutUser,isTheMenuOpen, isMenuOpen } = props

    const { user } = auth
   const { profileDetails } = profile;

   const handleLogOut = (e) => {
   
    logoutUser();
    history.push('/')
  }
  const history = useHistory()
  //const location = useLocation()
  const adminRoutes = [
    {
      title : "Dashboard",
      itemId : "/admin/dashboard/payment-history",
      elemBefore : () => <FontAwesomeIcon icon={faTachometerAlt} />
    },
    
    {
      title: "Profile",
      itemId: "/admin/profile/about",
      elemBefore: () => <FontAwesomeIcon icon={faUser} />,
      // subNav: [
      //   {
      //     title: "change password",
      //     itemId: "/admin/profile/change-password",
      //   },
      //   {
      //     title: "update details",
      //     itemId: "/admin/profile/edit-account",
      //   },
      // ],
    },
   
    {
      title: "Manage Users",
      itemId: "/admin/manage-users",
      elemBefore: () => <FontAwesomeIcon icon={faUsers} />,
      subNav: [
        {
          title: "Add Librarian",
          itemId: "/admin/add-librarian",
        },
        {
          title: "View Librarians",
          itemId: "/admin/all-librarians",
        },
        {
          title: "Add User",
          itemId: "/admin/add-user",
        },
        {
          title: "View Users",
          itemId: "/admin/all-users",
        },

      ],
    },
   
  ]; 

  const librarianRoutes = [
    {
      title : "Dashboard",
      itemId : "/admin/dashboard/payment-history",
      elemBefore : () => <FontAwesomeIcon icon={faTachometerAlt} />
      
    },
    {
      title: "Profile",
      itemId: "/admin/profile/about",
      elemBefore: () => <FontAwesomeIcon icon={faUser} />,
      
    },
    {
      title: "Manage Resource",
      itemId: "/management",
      elemBefore: () => <FontAwesomeIcon icon={faBookMedical} />,
      subNav: [
        {
          title: "Add Physical Book",
          itemId: "/admin/add-book",
        },
        {
          title: "Add Digital Book",
          itemId: "/admin/add-digitalbook",
        },
        {
          title: "View physical Books",
          itemId: "/admin/view-physical-books",
        },
        {
          title: "View digital books",
          itemId: "/admin/view-digital-books",
        },
        {
          title : "Add resources",
          itemId : "/admin/add-new-resources"
        },
        {
          title: 'Resources',
          itemId: "/admin/list-of-resources"
        }
        ,
        {
          title: 'KOHA',
          itemId: "http://koha.adminkuhn.ch:8080/"
        }
      ],
    },
    {
      title: "Borrowed books",
      itemId: "/admin/view-all-issued-books",
      elemBefore: () => <FontAwesomeIcon icon={faList} />,
    },
    {
      title: "Reserved books",
      itemId: "/admin/view-all-reserved-books",
      elemBefore: () => <FontAwesomeIcon icon={faList} />,
    },
    {
      title: "Overdue books",
      itemId: "/admin/view-all-overdue-books",
      elemBefore: () => <FontAwesomeIcon icon={faList} />,
    },
    {
      title: "Manage Users",
      itemId: "/admin/manage-users",
      elemBefore: () => <FontAwesomeIcon icon={faUsers} />,
      subNav: [
        
        {
          title: "Add User",
          itemId: "/admin/add-user",
        },
        {
          title: "View Users",
          itemId: "/admin/all-users",
        },

      ],
    },
    
  ]
    return (
     <div >
      <aside className={isTheMenuOpen ? "fixed inset-y-0  z-10 flex flex-col flex-shrink-0 w-64 max-h-screen overflow-hidden transition-all transform bg-white border-r shadow-lg lg:z-auto lg:static lg:shadow-none" : "hidden" }>
        <div className="flex items-center justify-between flex-shrink-0 p-2">
          <button  className="p-2 rounded-md lg:hidden" onClick={() => isMenuOpen(false)}>
            <svg
              className="w-6 h-6 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          </div>
          <div>
        <div className=" flex flex-col  items-center justify-center mb-2 ">
             <img
               src={profileDetails && profileDetails.profileImage}
               alt="AdminLTE Logo"
               className="brand-image img-circle elevation-3 w-14 rounded-full"
               style={{ opacity: ".8" }}
             />
             <h1 className="pr-5 text-lg ">
                Hello { profileDetails && profileDetails.firstName}
              
             </h1>
             
             <h1 className="pl-5 mb-3 text-md font-weight-light">{profileDetails && profileDetails.email}</h1>
           </div>
           </div>
        

          {/* side navLinks */}
          <nav className="flex-1 overflow-hidden hover:overflow-y-auto">
            <Navigation
             //you can use your own router's api to get pathname
             activeItemId={'/admin/dashboard/all-users'}
             onSelect={({ itemId }) => {
                //maybe push to the route
              itemId.charAt(0) === '/' ? history.push(itemId) : handleResourcesLink(itemId);
             }}
             items={user.role === "Librarian" ? librarianRoutes : adminRoutes}
           />
            
          
        </nav>
        
        <div className="flex-shrink-0 p-2 border-t max-h-14">
          <button
          onClick={ () => handleLogOut()}
            className="flex items-center justify-center w-full px-4 py-2 space-x-1 font-medium tracking-wider uppercase bg-gray-100 border rounded-md focus:outline-none focus:ring"
          >
            <span>
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </span>
            <span > Logout </span>
          </button>
        </div>

      </aside>
      </div>
    );
}

const mapStateToProps = (state, ownProps) => {
  
  return {
    profile: state.profile,
    auth : state.auth,
    isTheMenuOpen : ownProps.isTheMenuOpen,
    isMenuOpen : ownProps.isMenuOpen
  };
};

export default connect(mapStateToProps, { logoutUser })(AdminSideMenu);
