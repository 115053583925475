import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import { persistStore } from 'redux-persist'
import rootReducer from "./reducers";



const middleware = [thunk, logger];

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware)
  )
);

export const myPersiststore = persistStore(store)

