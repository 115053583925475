import React from 'react'

import bookImage from '../../../assets/img/bookImage.jpg'



const CategoryCard = ({category, book}) => {
    return (
      <div className="shadow-lg p-4 mt-3 md:mt-0  rounded-md" >
        <img src={ bookImage} alt="" className="mx-auto w-20" />
        <div>
         
        <h1 className="my-2 text-gray-500 text-lg text-bold ">{category.category}</h1>
        </div>
        <div>
          <h1 className="my-2 text-gray-500 text-md  ">Number of Books: { book }</h1>
        </div>
        
      </div>
    );
}

export default CategoryCard
