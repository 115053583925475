import React from 'react';
import DigitalBooksList from './component/DigitalBooksList';
import PdfCard from './component/PdfCard'




const FilterComponent = ({ filterText, onFilter }) => (
        <>
          <input id="search" className="p-2 border border-green-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Title and Author" aria-label="Search Input" value={filterText} onChange={onFilter} />
          
        </>
      );

const DigitalCategory = ({ location }) => {


        const [filterText, setFilterText] = React.useState('');

        const searchInputField = (rows) => {
        if(!rows) return []
        return rows.filter(
        (row) =>
        row.title.toLowerCase().includes(filterText.toLowerCase()) ||
        row.author.toLowerCase().includes(filterText.toLowerCase())
        );
        };

        let books = location.state.books;
        
        return(
                <div className="p-3 md:p-5 lg:p-5">
                     <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
                
                        <DigitalBooksList books={ searchInputField(books)} />
                        
                 
      </div>
        )
}


export default DigitalCategory