import { combineReducers } from "redux";
import authReducer from "./authReducer";
import adminReducer from './adminReducer'
import bookReducer from "./bookReducer";
// import errorReducer from "./errorReducer";

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'


import profileReducer from './profileReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist : ["profile",]
}


const rootReducer = combineReducers({
  auth: authReducer,
   admin : adminReducer,
   book : bookReducer,
   profile : profileReducer
//   errors: errorReducer,
});

export default persistReducer(persistConfig, rootReducer)




// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'

// import AuthReducer from "./auth/authReducer";




// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist : ["profile"]
// }


// const rootReducer = combineReducers({
//   auth: AuthReducer,
//   profile: profileReducer,
//   admin: adminReducer,
//   book: bookReducer,
// });




// export default persistReducer(persistConfig, rootReducer);