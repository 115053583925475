import React from 'react'
//import PdfImage from '../../../assets/img/pdfImage.jpeg'
// import { PDFReader } from "reactjs-pdf-reader";

const PdfCard = (props) => {

  const { title, author, totalPages, year, bookUrl } = props.pdfData
    return (
      <div className="w-full py-2 lg:max-w-full mt-2 border-r border-b border-l  rounded-lg shadow-md lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r">
      <img className="h-14 md:h-20  w-46 m-auto rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" src="https://img.flaticon.com/icons/png/512/337/337946.png?size=1200x630f&pad=10,10,10,10&ext=png&bg=FFFFFFFF" alt="pdf  fetch online" title="River" />
      
      <div className=" p-2 flex flex-col justify-between leading-normal">
        <div className="mb-4 md:mb-8">
          <div className="text-gray-900 text-sm font-bold text-lg mb-1 md:mb-2">{ title }</div>
          <p className="text-gray-700 text-sm md:text-base">Author: { author }</p>
          <p className="text-gray-700 text-sm md:text-base"> Publication Year: { year }</p>
          <p className="text-gray-700 text-sm md:text-base">Total Pages: { totalPages }</p>
        </div>
        <div>
          <a  href={ bookUrl } className="bg-blue-600 text-white px-3 py-2 mr-2 rounded-md">Download/View</a>
        </div>
      </div>
    </div>
    );
}

export default PdfCard
