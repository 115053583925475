import React,{ useState, useEffect } from 'react'

import {
        ModalProvider,
        Modal,
        useModal,
        ModalTransition,
      } from "react-simple-hook-modal";
      import "react-simple-hook-modal/dist/styles.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../utils/Axios'
import Spinner from '../../../components/spinner/Spinner'

const ListOfResources = () => {

        const [ loading, setLoading ] = useState(false);
        const [ resources, setResources ] = useState(null)
        const [ resourceId, setResourceId ] = useState(null)
        const [ modalOpen, setModalOpen ] = useState(false)


        const failedNotify = (message) => toast.error( message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

        const fetchResourcesList = async () => {
                setLoading(true)
                try {
                        const response = await http.get('/api/resources')
                        setLoading(false)
                        setResources(response.data.data)
                } catch (error) {
                        
                        error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
                        setLoading(false)
                }
        }
        // delete resourceList

        const deleteResource = async (data) => {
                try {
                        const response = await http.post('/api/resources/remove', data)
                        if(response){
                                setLoading(false)
                                fetchResourcesList()
                        }
                } catch (error) {
                        error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
                        setLoading(false)
                }
        }

        useEffect(() => {
                fetchResourcesList()
        },[])

        return (
                <div className="p-5 w-full">
                        <ToastContainer />
                        <ModalProvider>
                        {
                                loading ? (
                                        <Spinner />
                                ) : (
                                        <>
                                                <h1 className="text-xl font-semibold p-4">Resources</h1>
                                                <div className=" md:grid grid-cols-3 gap-4 justify-center">
                                                        {
                                                                resources ? resources.map( resource => {
                                                                        return(
                                                                                <div key={resource._id} className="shadow-xl mx-4 py-3 px-1 rounded-lg">
                                                                                        <h1 className="text-gray-600 ml-4 text-lg" >{ resource.title }</h1>
                                                                                        <div className="flex flex-row items-center ">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11 py-3 px-0 stroke-current text-green-600 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                                                                </svg>
                                                                                                <a className="text-gray-600" href={resource.url} target="_blank" rel="noreferrer" >Visit Link</a>
                                                                                        </div>
                                                                                        <div className="flex flex-row items-center cursor-pointer" onClick={() => {
                                                                                                setModalOpen(true)
                                                                                                setResourceId(resource._id)
                                                                                        } 
                                                                                }>
                                                                                        
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11 py-3 px-0 stroke-current text-red-600 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                                </svg>
                                                                                                <h1>Delete</h1>
                                                                                        </div>
                                                                                </div>
                                                                        )
                                                                }) : (
                                                                        <div>
                                                                                Ops! No resources Available
                                                                        </div>
                                                                )

                                                        }
                                                </div>
                                        </>
                                )
                        }
                        <Modal
              id="any-unique-identifier"
              isOpen={modalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to delete this Resources?
                </h1>
                <div>
                  <button
                    onClick={() => {
                        setModalOpen(false);
                    }}
                    className="bg-red-500 cursor-pointer text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={  () => {
                        deleteResource({ 
                        _id : localStorage.userId,
                        resourceId 
                      })
                      setModalOpen(false)
                       
                    }}
                    className="bg-blue-500 cursor-pointer text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    { loading ? 'Processing' : 'Proceed'}
                  </button>
                </div>
              </div>
            </Modal>

                        </ModalProvider>
                </div>
        )
}

export default ListOfResources
