import React, { useState, useEffect } from 'react'
import http from '../../../utils/Axios'

import Spinner from '../../../components/spinner/Spinner'

const UserDuedBooks = () => {
        
        const [ overDueBooks, setOverDueBooks ] =useState([])
        const [ loading, setLoading ] = useState(false)

        const _id = localStorage.userId

        const fetchDueBooks = async () => {
                setLoading(true)
                try {
                        let response = await http.get(`/api/book/member-overdue-books/${_id}`)
                        console.log(response)
                        setLoading(false)
                        response.data.status ? setOverDueBooks(response.data.data) : setOverDueBooks(null)
                        
                } catch (error) {
                        console.log(error)
                }
        }

        useEffect(() =>{
                console.log('use effect triggered')
                fetchDueBooks() 
        }, [])

        console.log(overDueBooks)
        return (
                <div>
                        {
                                loading ? ( <Spinner />) : (
                                        <div>
                                                {
                                                        !overDueBooks ? (
                                                                <div className="flex items-center justify-center">
                                                                        <h1 className="text-lg">Sorry you don't have any overbook available</h1>
                                                                </div>
                                                                
                                                        ) : (
                                                                <div>
                                                                        { overDueBooks && overDueBooks.map( overDueBook => {
                                                                                return(
                                                                                <div>
                                                                                        {overDueBook}
                                                                                </div>
                                                                                )
                                                                        })}
                                                                </div>
                                                        )
                                                }
                                        </div>
                                )
                        }
                </div>
        )
}

export default UserDuedBooks
