import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import http from '../utils/Axios'
import { NavLink, Route, Switch } from 'react-router-dom'
import AboutUser from './user/pages/AboutUser';
import ChangePassword from './user/pages/ChangePassword';
import EditAccount from './user/pages/EditAccount';

import Spinner from '../components/spinner/Spinner'


const UserProfile = ({match, profile}) => {

  const {  profileDetails, libraryCard } = profile 
  console.log(profileDetails)

  const [ libraryCardDetails, setLibraryCardDetails ] = useState({})
  const [ loading, setLoading ] = useState(false)

  // const fetchLibraryCardDetails = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await http.get(`/api/payment/libraryCard/${localStorage.userId}`)
  //     setLibraryCardDetails(response.data.data)
  //     setLoading(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   fetchLibraryCardDetails()
  // }, [])

    return(
      <div>
      {
        loading ? ( <Spinner /> ) : (
          <div className="p-5 md:p-7">
          <h1 className="text-2xl text-black text-bold">My Profile</h1> 
          <hr className="text-red-400 h-7 my-5" />
          <div className="flex items-center justify-center">
          <div className="shadow-lg rounded-lg flex flex-col items-center justify-center text-center md:w-3/5 w-full">
              <div className="flex flex-col items-center justify-center">
              <img src={ profileDetails.profileImage } alt="avatar" className="w-24 rounded-full" />
              <h1 className="text-bold text-lg">{ profileDetails.firstName } { profileDetails.lastName }</h1>
              <h1>{ profileDetails.email }</h1>
              </div>
              <nav className="grid grid-cols-3 gap-1 mt-4">
                  {/* Links for routing */}
                  <NavLink to="/user/profile/about"  activeStyle={{
  fontWeight: "bold",
  color: "green"
}} className="text-gray-500 text-sm md:text-lg">About</NavLink>
                  <NavLink to="/user/profile/edit-account" activeStyle={{
  fontWeight: "bold",
  color: "green"
}} className="text-gray-500 text-sm md:text-lg">Edit Profile</NavLink>
                  <NavLink to="/user/profile/change-password" activeStyle={{
  fontWeight: "bold",
  color: "green"
}} className="text-gray-500 text-sm md:text-lg">Change Password</NavLink>
                  
              </nav>
              <hr className="bg-red-600 "/>
              {/* Links srouting routes */}
              <Switch>
              <Route path={`${match.path}/about`}  render = { (props) => <AboutUser { ...props } profileDetails={profileDetails} />} />
              <Route path={`${match.path}/change-password`} component={ChangePassword} />
              <Route path={`${match.path}/edit-account`}  component = {EditAccount }  />
              </Switch>
              
          </div>
          </div>
      </div>
        )
      }
       </div>
    )
}

const mapStateToProps = state => {
  return{
    profile : state.profile
  }
}


export default connect(mapStateToProps)(UserProfile)