import {
  GET_ERRORS,
  GET_PROFILE,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  SET_PASSWORD,
  PASSWORD_SUCCESSFUL,
  UPDATE_PROFILE,
  PROFILE_UPDATED_SUCCESSFULLY,
} from "../actions/types";



const initialState = {
  profileDetails: {},
  loading: false,
  message : "",
  error : ''
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error : ''
      };
    case GET_PROFILE:
      return {
      ...state,
        profileDetails: action.payload,
        loading: false,
        error : ''
      };
    case CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        profileDetails: {},
        loading : false,
        message : '',
        error: "",
      };
    case SET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        message : action.payload,
        error: "",
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UPDATED_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        message : action.payload,
        error: "",
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export default profileReducer;