import React, { useState } from 'react'
import http from '../../../utils/Axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";


const notify = (message) => toast.success( message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      
        
        const failedNotify = (message) => toast.error( message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

const AddResources = () => {

        const [ loading, setLoading ] = useState(false)

        const addResource = async (data ) => {
                setLoading(true)
                try {
                        const response = await http
                        .post("/api/resources/create", data, {
                          headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Credentials": "true",
                            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                            "Access-Control-Allow-Headers":
                              "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                              Authorization: "Bearer " + localStorage.token,
                          },
                         } )
                         console.log(response)
                         setLoading(false)
                        response.data.status ? notify(response.data.message) : failedNotify(response.data.message)
                } catch (error) {
                        error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
                        setLoading(false)
                }
        }

        const initialValues = {
                _id : localStorage.userId,
                title: "",
                url : "",
                
              };
            
              const validationSchema = Yup.object({
                title: Yup.string().required("Sorry the resource's Title is required!"),
                
                url: Yup.string().required('Please enter resources URL'),
              });


              const onSubmit = (values, { resetForm }) => {
                addResource(values)
                resetForm()
              };
             
        return (
                <div className="grid  place-items-center">
                <ToastContainer />
                    <div className="p-5 bg-white">
                      <h1 className="text-xl font-semibold">Add New Resources</h1>
                     
                      <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                
                    <Form className="mt-6" autoComplete="off">
                      
                      <label
                        htmlFor="title"
                        className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                      >
                        Resource Title
                      </label>
                      <Field
                        id="title"
                        type="text"
                        name="title"
                        placeholder="Enter Resources Title"
                        className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                        required
                      />
                      <ErrorMessage name="title" />
                      <label
                        htmlFor="category"
                        className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                      >
                        Category
                      </label>
                      <Field
                        id="url"
                        type="text"
                        name="url"
                        className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                        required
                        placeholder="Enter resources url"
                      />
                      <ErrorMessage name="url" />
          
                      <button
                        type="submit"
                        className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-green-700 shadow-lg focus:outline-none hover:bg-green-900 hover:shadow-none"
                      >
                        { loading ? 'Processing...' : 'Add Resource' }
                      </button>
                    </Form>
                
                  </Formik>
                     </div>
              </div>
        )
}

export default AddResources
