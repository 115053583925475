import React, { useState, useEffect } from 'react'

import moment from 'moment'


import {
  ModalProvider,
  Modal,

  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";

import http from '../../../utils/Axios'

import Spinner from '../../../components/spinner/Spinner'


const IssuedBooks = () => {
        const token = localStorage.jwtToken;


    const [ userIssuedBooks, setUserIssuedBooks ] =useState([])

    const [ loading, setLoading ] = useState(false)
    const [ bookData, setBookData ] = useState({})

    const _id = localStorage.userId
      const [ isCancelBorrowedModal, setIsCancelBorrowedModal ] = useState(false)

  
  

        const fetchUserIssuedBooks = async () => {
                setLoading(true)
                try {
                        let response = await http.get(`/api/book/member-borrowed-books/${_id}`)
                        console.log(response)
                        setLoading(false)
                        response.data.status ? setUserIssuedBooks(response.data.data) : setUserIssuedBooks(null)
                        
                } catch (error) {
                        console.log(error)
                }
        }

        const cancelBorrowedBook = async( data ) => {
                try {
                  const res = await http
                    .post("/api/book/cancel-borrow", data,{
                      headers: {
                        'Content-Type': 'application/json',
                        'Accept' : 'application/json',
                        'Authorization': `Bearer ${token}`,
                      },
                    })
            
                    console.log(res.data)
                    console.log('successful')
                    fetchUserIssuedBooks()
                    
                } catch (err) {
                  console.log(err)
                }
              }
              
        useEffect(() =>{
                console.log('use effect triggered')
                fetchUserIssuedBooks() 
        }, [])

                console.log(userIssuedBooks)
                console.log(bookData)
    return (
        <div className="w-full ">
                <ModalProvider>
            {
                                loading ? ( <Spinner />) : (
                                        <div>
                                                {
                                                        !userIssuedBooks ? (
                                                                <div className="flex items-center justify-center">
                                                                        <h1 className="text-lg">Sorry you don't have any borrowed book available</h1>
                                                                </div>
                                                                
                                                        ) : (
                                                                <div className="flex flex-col">
  <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Book
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Issued Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
               Returned Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              userIssuedBooks && userIssuedBooks.map(issuedBookData => {
                const { borrowedDate, expectedReturnDate } = issuedBookData


                const newBorrowedDate = moment(borrowedDate).format('dddd, MMMM Do YYYY')
                const newExpectedReturnDate = moment(expectedReturnDate).format('dddd, MMMM Do YYYY')

                return(
                  <tr key={ issuedBookData._id }>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                    { issuedBookData.issuedBookId.title }
                    </div>
                    <div className="text-sm text-gray-500">
                      { issuedBookData.issuedBookId.author}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{ newBorrowedDate }</div>
                
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
              
              <div className="text-sm text-gray-900">{ newExpectedReturnDate }</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              { issuedBookData.borrowStatus }
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                {
                  issuedBookData.borrowStatus === "Not Collected" ? <button onClick={ () => {
                        setBookData(issuedBookData)
                          setIsCancelBorrowedModal(true)
                        }} className="bg-red-400 text-white px-4 py-2 rounded-md shadow-lg">Cancel</button> : null
                }
              </td>
            </tr>
            
                )
              })
            }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
                                                        )
                                                }
                                        </div>
                                )
                        }
                        <Modal
              id="any-unique-identifier"
              isOpen={isCancelBorrowedModal}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to cancel issuing of this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsCancelBorrowedModal(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                   onClick={ async () => {
                       await  cancelBorrowedBook({
                                _id :localStorage.userId,
                        borrowedId : bookData._id,
                        borrowedBookId: bookData.issuedBookId._id
                        })   
                        setIsCancelBorrowedModal(false);
                   }

                   }
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>

            </ModalProvider>
        </div>
    )
}

export default IssuedBooks
