import React from 'react';

import { Link } from 'react-router-dom'



const Menu = ({ isMenuOpen, menu, }) => {

  // handle logging out
 
  return (
    <div
      className={
        isMenuOpen
          ? "my-5 flex flex-col justify-end  transition-transform delay-1000 ease-in container"
          : "hidden"
      }
      onClick={menu}
    >
      <Link
        to="/"
        className="m-2 mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
      >
        Home
      </Link>
      
      <Link
        to="/resources"
        className="m-2 mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
      >
      Resources
      </Link>
      <Link
        to="/services"
        className="m-2 mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
      >
        Services
      </Link>
      <Link
        to="/about-us"
        className="m-2 mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
      >
        About
      </Link>
      <Link
        to="/contact"
        className="m-2 mr-5 text-md font-semibold text-gray-600 hover:text-green-800"
      >
        Contact
      </Link>
      
     
    </div>
  );
}

export default Menu
