import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';

import {
  ModalProvider,
  Modal,

  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";



import http from '../../../utils/Axios'

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="my-2 p-2 border border-gray-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Name, Email and Book" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);

const ViewIssuedBooks = () => {
  const [issuedBooks, setIssuedBooks] = useState([]);
  const [ isCheckOutModal, setIsCheckOutModal ] =useState(false)
  const [ isCancelBorrowedModal, setIsCancelBorrowedModal ] = useState(false)
  const [ isReturnBookModal, setIsReturnBookModal ] = useState(false)
  const [ singleBookData, setSingleBookData ] = useState({})
  const [ loading, setIsLoading ] = useState(false)
  

  
  const token = localStorage.jwtToken;
  const userId = localStorage.userId;

  const fetchIssuedBooks = async() => {
    setIsLoading(true) 
    try {
      const response = await http
      .get(`/api/book/issued-books/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
    setIsLoading(false)
        setIssuedBooks(response.data.data);
      
    } catch (error) {
      console.log(error)
    }
    
  };

  // cancel borrowedBooks
  const cancelBorrowedBook = async( data ) => {
    try {
      const res = await http
        .post("/api/book/cancel-borrow", data,{
          headers: {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        })

        fetchIssuedBooks()
    } catch (err) {
      console.log(err)
    }
  }


  // check out books

  const checkOutBook = async(data) => {
    try{
      const res = await http
        .post("/api/book/checkout", data,{
          headers: {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        })

        console.log(res.data)
        console.log('successful')
        fetchIssuedBooks()
    }catch(err){
      console.log(err);
    }
  }

  // return borrowedBook

  const returnBorrowedBook = async(data) => {
    console.log(`data ${data}`)
    try{
      const res = await http
        .post("/api/book/return-borrow", data,{
          headers: {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        })

        fetchIssuedBooks()
    }catch(err){
      console.log(err);
    }
  }

  
  

  useEffect(() => {
    fetchIssuedBooks();
  }, []);
  
  const columns = [
    {
      name: 'Name',
      cell : issuedBooks => <div>{ issuedBooks.borrowedBy.firstName} {issuedBooks.borrowedBy.lastName}</div>,
      sortable: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: issuedBooks => <div>{ issuedBooks.borrowedBy.email }</div>
    },
    {
      name: 'Address',
      cell : issuedBooks => <div>{ issuedBooks.borrowedBy.address}</div>,
      sortable: true,
      
    },
    {
      name: 'Book',
      style:{
        padding: '10px',
        width: '120px'
      },
      cell : issuedBooks => <div className="w-full">{ issuedBooks.issuedBookId?.title }</div>,
      sortable: true,
      
    },
    {
      name: 'Status',
      cell : issuedBooks => <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-green-800">{issuedBooks.borrowStatus}</div>
      
    },
      {
      name: 'Action',
      style:{
        paddingTop: '10px',
        paddingBottom : '10px'
        
      },
      
      cell:  issuedBooks =><div className="w-full text-center"> { issuedBooks.borrowStatus === 'Collected' ? <div onClick={ () => {
        setSingleBookData(issuedBooks) 
        setIsReturnBookModal(true)
      }} className="bg-green-700 text-white p-2 rounded-md cursor-pointer">Return</div> : <div className="flex flex-col items-between justify-between"><div onClick={ () => {
        setSingleBookData(issuedBooks) 
        setIsCheckOutModal(true)}} className="bg-green-600 cursor-pointer text-white p-2 rounded-md mb-3">Check Out</div> <div onClick={ () => {
        setSingleBookData(issuedBooks) 
        setIsCancelBorrowedModal(true)}} className="bg-red-400 text-white p-2 rounded-md cursor-pointer">Cancel</div> </div>} </div>
    }
  ];

  const customStyles = {
    
    headRow: {
        style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderColor: 'red'
      },
    },
    headCells: {
      style: {
        backgroundColor: 'green'
      },
    },
    cells: {
      style: {
        
      },
    },
  };


const [filterText, setFilterText] = React.useState('');

const searchInputField = (rows) => {
  if(!rows) return []
  return rows.filter(
    (row) =>
      row.borrowedBy.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.borrowedBy.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.borrowedBy.email.toLowerCase().includes(filterText.toLowerCase()) ||
      row.issuedBookId.title.toLowerCase().includes(filterText.toLowerCase())
  );
};

  
  return (
    <>
    <ModalProvider>
    <div className="px-5">
       <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
      <DataTable
        title="Borrowed Books"
        columns={columns}
        data={searchInputField(issuedBooks)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
      
    </div>
    {/* cancel borrowed modal */}
    <Modal
              id="any-unique-identifier"
              isOpen={isCancelBorrowedModal}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to cancel issuing of this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsCancelBorrowedModal(false);
                    }}
                    className="bg-red-700 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={ async () => {
                      await cancelBorrowedBook({
                        _id :localStorage.userId,
                        borrowedId : singleBookData._id,
                        borrowedBookId: singleBookData.issuedBookId._id
                      })
                      setIsCancelBorrowedModal(false);
                    }}
                    className="bg-blue-700 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>
     {/* checkout modal */}
     <Modal
              id="any-unique-identifier"
              isOpen={isCheckOutModal}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to check out this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsCheckOutModal(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={ async () => {
                      await checkOutBook({
                        _id :localStorage.userId,
                        issuedId : singleBookData._id
                      })
                      setIsCheckOutModal(false);
                    }}
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>
                    {/* return book modal */}
                    <Modal
              id="any-unique-identifier"
              isOpen={isReturnBookModal}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to return this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsReturnBookModal(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={ async () => {
                      await returnBorrowedBook({
                        _id :localStorage.userId,
                        borrowedId : singleBookData._id,
                        borrowedBookId: singleBookData.issuedBookId._id
                      })
                      setIsReturnBookModal(false);
                    }}
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>
    </ModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ViewIssuedBooks);
