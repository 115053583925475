import {
  ADD_BOOK,
  BOOK_SUCCESSFUL,
  DELETE_BOOK,
  GET_ERRORS,
  MESSAGE,
  RESERVE_BOOK,
  BORROW_BOOK,
} from "../actions/types";


const initialState ={
    loading: false,
    error : "",
    message : ''
}


const bookReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_BOOK:
        return {
          ...state,
          loading: true,
        };
      case BOOK_SUCCESSFUL:
        return {
          ...state,
          loading: false,
          message : action.payload,
          error: "",
        };
      case DELETE_BOOK:
        return {
          ...state,
          loading: true,
          error: "",
        };
        case RESERVE_BOOK:
          return{
            ...state,
            loading : true,
            error : "",
            message : ""
          }
        case BORROW_BOOK: 
        return{
          ...state,
          loading: true,
          error : "",
          message : ""
        }
        case MESSAGE :
          return{
            ...state,
            loading: false,
            error : "",
            message : action.payload
          }
      case GET_ERRORS:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
}

export default bookReducer