import React from "react";

import Spinner from "../../../components/spinner/Spinner";
import CheckSvg from "../../../assets/img/checked.png";

import { addNewUser } from "../../../Redux/actions/adminActions";

import {
  ModalProvider,
  Modal,
  useModal,
  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";
import { connect } from "react-redux";


import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";



const AddUser = ({ auth, addNewUser, admin }) => {
  const { isModalOpen, openModal, closeModal } = useModal();

  const { loading, error } = admin;

  const _id = localStorage.userId

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    amount : '',
    _id: _id,
  };
  const onSubmit = (values) => {
    addNewUser(values);
    openModal();
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name cant't be empty"),
    lastName: Yup.string().required("Last name can't be empty"),
    email: Yup.string()
      .email("Looks like this is not an email")
      .required("Email cannot be empty"),
    phone: Yup.string().required("Sorry your phone number is requirred"),
    address: Yup.string().required("Sorry your address is required"),
    amount : Yup.number().required('Please enter the amount paid')
  });
  return (
    <div>
      <ModalProvider>
        {loading ? (
          <Spinner />
        ) : (
          <div className="grid  place-items-center">
            <div className="w-11/12  bg-white sm:w-8/12 md:w-1/2 lg:w-5/12">
              <h1 className="text-xl font-semibold">
                <span className="font-normal">Add A User</span>
              </h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form className="mt-6" autoComplete="off">
                  <div className="flex justify-between gap-3">
                    <span className="w-1/2">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                      >
                        Firstname
                      </label>
                      <Field
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="John"
                        autoComplete="given-name"
                        className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                        required
                      />
                      <ErrorMessage name="firstName" />
                    </span>
                    <span className="w-1/2">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium py-2 leading-relaxed tracking-tighter text-gray-700"
                      >
                        Lastname
                      </label>
                      <Field
                        id="lastName"
                        type="text"
                        name="lastName"
                        placeholder="Doe"
                        autoComplete="family-name"
                        className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                        required
                      />
                      <ErrorMessage name="lastName" />
                    </span>
                  </div>
                  <label
                    htmlFor="email"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    E-mail
                  </label>
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    placeholder="john.doe@company.com"
                    autoComplete="email"
                    className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                    required
                  />
                  <ErrorMessage name="email" />
                  <label
                    htmlFor="phone"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Phone Number
                  </label>
                  <Field
                    id="phone"
                    type="phone"
                    name="phone"
                    placeholder="phone Number"
                    className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                    required
                  />
                  <ErrorMessage name="phone" />
                  <label
                    htmlFor="address"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Address
                  </label>
                  <Field
                    id="address"
                    type="text"
                    name="address"
                    placeholder="Enter your address"
                    className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                    required
                  />
                  <ErrorMessage name="address" />
                  <label
                    htmlFor="amount"
                    className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Amount
                  </label>
                  <Field
                    id="amount"
                    type="number"
                    name="amount"
                    placeholder="Enter amount received"
                    className="w-full px-4 py-2 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-green-500 focus:bg-white focus:ring-0"
                    required
                  />
                  <ErrorMessage name="amount" />
                  <button
                    type="submit"
                    className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-green-700 shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none"
                  >
                    Register
                  </button>
                </Form>
              </Formik>
            </div>

            <Modal
              id="any-unique-identifier"
              isOpen={isModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                {!error ? (
                  <>
                    {" "}
                    <div>
                      <img src={CheckSvg} alt="success " />
                    </div>
                    <h1 className="my-5 text-center">
                      Your Registration was successful. Please check your mail
                      to activate your account{" "}
                    </h1>
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg"
                    >
                      close
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <div></div>
                    <h1 className="my-5 text-center">{error}</h1>
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg"
                    >
                      close
                    </button>
                  </>
                )}
              </div>
            </Modal>
          </div>
        )}
      </ModalProvider>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    admin: state.admin,
  };
};
export default connect(mapStateToProps,  {addNewUser })(AddUser);
