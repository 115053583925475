import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";


import http from '../../../utils/Axios'

const AdminChangePassword = () => {

  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState({})

    const _id = localStorage.userId
    const token = localStorage.token

    

    const notify = (message) => toast.success( message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      const failedNotify = (message) => toast.error( message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    

      // changePassword function

      const changePasswordAction = async (passwordData) => {
        setLoading(true)
        try {
          const response = await http.post("/api/auth/password", passwordData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
              "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept, Authorization",
              Authorization: "Bearer " + token,
            },
          })
          setData(response)
          notify(response.data.message)
          setLoading(false)
        } catch (error) {
          failedNotify(error.message)
          setLoading(false)
        }
      }
  
   const initialValues = {
     _id: _id,
     password: "",
     confirmPassword: "",
   };
    const onSubmit = (values, resetForm) => {
      changePasswordAction({ password: values.confirmPassword, _id: _id });
      resetForm();
    };
  
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),

    //  img : Yup.string()
  });
  console.log(data)
  return (
    
          <div className=" flex flex-col items-center justify-center w-full ">
            <ToastContainer />
            <div className=" px-3 py-5 ">
              {" "}
              <h1>Change Password</h1>
              <p>Hints on getting your new password right:</p>
              <p>
                Your new password must be between 8 and 15 characters in length.
              </p>
            </div>

            <section className="text-gray-700 body-font">
              <div className="container px-4 pt-15  pb-24 mx-auto lg:px-4">
                <div className="flex flex-col w-full p-8 mx-auto mt-10 border rounded-lg  md:ml-auto md:mt-0">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form>
                      <div className="relative ">
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Current Password"
                          className="w-full px-4 py-2 mb-4 mr-4 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                          required
                        />
                        <ErrorMessage name="password" />
                      </div>
                      <div className="relative ">
                        <Field
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder=" New Password"
                          className="w-full px-4 py-2 mb-4 mr-4 text-base text-green-700 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                          required
                        />
                        <ErrorMessage name="confirmPassword" />
                      </div>

                      <button
                        type="submit"
                        className="px-8 py-2 font-semibold text-white transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:outline-none"
                      >
                        {loading ? "Loading..." : "Change Password" }
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </section>
           
      
    </div>
  );
};

export default   AdminChangePassword

