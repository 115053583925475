import React from 'react'
import PdfCard from './PdfCard'

const DigitalBooksList = ({ books }) => {
        return (
                <div className="md:grid grid-cols-3 gap-4  justify-center items-center text-center">
                        {
                                books.sort((a,b)=> a.title.localeCompare(b.title)).map(book => {
                                        return(
                                                <div key={book._id}>
                                                <PdfCard pdfData={ book } />
                                                </div>
                                        )
                                })                            
                        }
                </div>
        )
}

export default DigitalBooksList
