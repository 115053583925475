// import axios from 'axios';
import http from '../../utils/Axios'


import {
  
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  GET_PROFILE,
  GET_ERRORS,
  PROFILE_UPDATED_SUCCESSFULLY,
  UPDATE_PROFILE,
} from "./types";

const token = localStorage.jwtToken;



const getProfile = data => {
  return{
    type : GET_PROFILE,
    payload : data
  }
}

// Get current profile
export const getCurrentProfile = () =>async (dispatch) =>{
  
  try {
    const res = await http
      .get(`/api/auth/member/${localStorage.userId}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      await dispatch(getProfile(res.data.data))

      //  await localStorage.setItem('userDetails',JSON.stringify(res.data.data))

          // window.location.reload()
      
    
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.message
    });
  } 
};


// Create Profile
export const createProfile = (profileData, history) => dispatch => {
  http
    .post('/api/profile', profileData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Profile loading
export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING
  };
};



// Clear profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};









// profile updating

const setProfile = () => {
  return {
    type: UPDATE_PROFILE,
  };
};
const profileUpdated = (message) => {
  return {
    type: PROFILE_UPDATED_SUCCESSFULLY,
    payload : message
  };
};

export const updateProfile = (data) => (dispatch) => {
  try {
    dispatch(setProfile());
    http
      .post("/api/auth/update", data, {
        headers: {
            Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res)
        dispatch(profileUpdated(res.data.message));
        dispatch(getCurrentProfile())
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        })
      );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.message,
    });
  }
};



