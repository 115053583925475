import React, { useState } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'
import { getCurrentProfile } from '../../../Redux/actions/profileActions'

import http from '../../../utils/Axios';


const ProfileImageChangeComponent = ({ getCurrentProfile} ) => {
    const token = localStorage.jwtToken
    const _id = localStorage.userId

    const [ selectedFile , setSelectedFile ] = useState('')
    const [ progress, setProgress ] = useState()

 
// toastify

const notify = (message) => toast.success( message, {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  
  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

    
    // image upload function
 const uploadHandler = () => {
    
      const formData = new FormData();
      formData.append(
        "file",
        selectedFile,

      );
     formData.append("_id", _id);
   http({
     method: "post",
     url: "/api/auth/update-profile",
     data: formData,
     headers: {
      "Content-Type": "multipart/form-data",
       Authorization: "Bearer " + token,
     },
     
     onUploadProgress: data => {
      //Set the progress value to show the progress bar
      setProgress(Math.round((100 * data.loaded) / data.total))
    },
   })
     .then((res) => {
       console.log(res)
       
        notify(res.data.message)
         getCurrentProfile()
         
       
      
     })
     .catch(error => {
      error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
     
     })
  };
    return (
        <div>
          <ToastContainer />
            <input id="img"
                      type="file"
                      onChange={  event => {
                         setSelectedFile( event.target.files[0])
                         uploadHandler()
                      }}
                      name="img"
                      placeholder="Enter your first name"
                      className="block w-full p-2 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner "
              />
                  { 
              progress && (
                <div className="relative pt-1">
                  { progress === 100 ? <h1 className="text-gray-400 my-2">Uploading Completed</h1> : <h1 className="text-gray-400 my-2">Uploading in progress...</h1>}
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                    
                    <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                  </div>
                  <h1 className="text-gray-800 ">{progress} %</h1>
                </div>
              )
            }
        </div>
    )
}




export default connect(null, { getCurrentProfile })(ProfileImageChangeComponent)
