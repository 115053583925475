import http from '../../utils/Axios'
import {
  ADD_BOOK,
  BOOK_SUCCESSFUL,
  RESERVE_BOOK,
  ISSUE_BOOK,
  GET_ERRORS,
  BORROW_BOOK,
  MESSAGE,
} from "./types";

const token = localStorage.jwtToken;

const addBook = () => {
    return{
        type : ADD_BOOK
    }
}
const bookSuccesful = message => {
    return{
        type : BOOK_SUCCESSFUL,
        payload : message
    }
}
const bookIssue = ()=> {
    return{
        type:ISSUE_BOOK
    }
}
const bookIssuedSuccessfully = () => {
    return {
      type: "BOOK_ISSUED_SUCCESSFULLY",
    };
}

// reserve book initialization

const bookReserve = () => {
  return{
    type : RESERVE_BOOK
  }
}
//book reserve success
const reserveSuccessful = (response) => {
  return{
    type : MESSAGE,
    payload : response
  }
}

// book borrowing initialization
const bookBorrow = () => {
  return{
    type : BORROW_BOOK
  }
}
// borrow book success
const borrowSuccessful = (response) => {
  return{
    type : MESSAGE,
    payload : response
  }
}

// add digital book
// export const addDigitalBook = (bookDetails) => dispatch => {
//     try {
//     dispatch(addBook())
//     const formData = new FormData();
//     formData.append("file", bookDetails.file,);
//     formData.append("isbn", bookDetails.isbn);
//     formData.append("description", bookDetails.description);
//     formData.append("author", bookDetails.author);
//     formData.append("title", bookDetails.title);
//     formData.append("totalPages", bookDetails.totalPages);
//     formData.append("year", bookDetails.year);
//     formData.append("category", bookDetails.category);
//     formData.append("_id", bookDetails._id);
//         http
//           .post(
//             "/api/book/add/digital-book",
//             formData,{
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               'Accept' : 'multipart/form-data',
//               'Authorization': `Bearer ${token}`,
//             },
//             // onUploadProgress: data => {
//             //   //Set the progress value to show the progress bar
//             //   setProgress(Math.round((100 * data.loaded) / data.total))
//             // },
//           }
//           )
//           .then((res) => {
//             dispatch(bookSuccesful());
//           })
//           .catch((err) =>
//             dispatch({
//               type: GET_ERRORS,
//               payload: err.response.data.message,
//             })
//           );
//     } catch (err) {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data.message,
//       });
//     }
// } 

// add physical book
export const addPhysicalBook = (bookDetails) => (dispatch) => {
  try {
    dispatch(addBook());
    http
      .post("/api/book/add/physical-book", bookDetails,{
        headers: {
          'Content-Type': 'application/json',
          'Accept' : 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(bookSuccesful());
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        })
      );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.message,
    });
  }
}; 

export const issueBook = (data) => dispatch => {
     try {
       dispatch(bookIssue());
       http
         .post("/api/auth/login", data,{
          headers: {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        })
         .then((res) => {
           dispatch(bookIssuedSuccessfully());
         })
         .catch((err) =>
           dispatch({
             type: GET_ERRORS,
             payload: err.response.data.message,
           })
         );
     } catch (err) {
       dispatch({
         type: GET_ERRORS,
         payload: err.response.data.message,
       });
     }
}



// borrow books

export const borrowBook = (data) => dispatch => {
  try {
    dispatch(bookBorrow());
    
    http
      .post("/api/book/issue-book", data,{
        headers: {
          'Content-Type': 'application/json',
          'Accept' : 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(borrowSuccessful(res.data.message));
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        })
      );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.message,
    });
  }
}

// reserve books



export const reserveBook = data => dispatch => {
  try {
    dispatch(bookReserve())
    http
      .post("/api/book/reserve-book", data,{
        headers: {
          'Content-Type': 'application/json',
          'Accept' : 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(reserveSuccessful(res.data.message))
      })
      .catch((err) =>
        console.log(err)
      );
  } catch (err) {
    console.log(err)
  }
}


// cancel reservation


