import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../utils/Axios'
import DataTable from 'react-data-table-component';

import Spinner from '../../../components/spinner/Spinner'

import {
  ModalProvider,
  Modal,

  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";




const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="my-2 p-2 border border-gray-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Name, Email" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);

const LibrarianListPage = ( ) => {

  const  _id  = localStorage.userId
  const token = localStorage.jwtToken
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ librarians, setLibrarians ] = useState([]);
  const [loading, setLoading] = useState(false)

  // openModalHandler 
  const modalHandler = () => setIsModalOpen(true)


  const [ userId, setUserId ] = useState(null)


const notify = (message) => toast.success( message, {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

  const fetchLibrarians = (() => {

    setLoading(true)
    http.get(`/api/auth/librarians/${_id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data.data);
        setLibrarians(res.data.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      })
    })

    // delete librarian

    const deleteLibrarian = async (data) => {
      setLoading(true)
      try {
        const response = await http
        .post("/api/auth/remove/librarian", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            Authorization: "Bearer " + token,
          },
        })
        console.log(response)

        if(response){
          setLoading(false)
          setIsModalOpen(false);
          fetchLibrarians()
          notify('User Deleted Successfully')
          
        }
        
      } catch (error) {
        setLoading(false)
        setIsModalOpen(false)
        error.message === 'Network Error' ? failedNotify('Network Error') : failedNotify('Connection Timeout') 
      }
    }

  

  
  
 useEffect(() => {
   fetchLibrarians()
 }, [])
  //  filter
  
  const columns = [
    {
      name: 'Name',
      cell : librarians => <div>{ librarians.firstName} {librarians.lastName}</div>,
      sortable: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      maxWidth: '800px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: librarians => <div>{ librarians.email }</div>
    },
    {
      name : 'Phone Number',
      cell : librarians => <div>{ librarians.phone }</div>
    },
    {
      name: 'Address',
      cell : librarians => <div>{ librarians.address}</div>,
      sortable: true,
      
    },
    
      {
      name: 'Action',
      button: true,
      cell:  librarians => <svg
      onClick={ () => {
        modalHandler();
        setUserId(librarians._id);}
      }
      className="w-6 h-6 stroke-current text-red-600 cursor-pointer"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headRow: {
        style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderColor: 'red'
      },
    },
    headCells: {
      style: {
        backgroundColor: 'green'
      },
    },
    cells: {
      style: {
        
      },
    },
  };


  

  // search functions logic
  const [filterText, setFilterText] = React.useState('');

const searchInputField = (rows) => {
  if(!rows) return []
  return rows.filter(
    (row) =>
      row.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.email.toLowerCase().includes(filterText.toLowerCase()) 
    
  );
};




  return (
    <div className="p-7 w-full ">
      <ToastContainer />
      {loading ? (
        <Spinner />
      ) : (
        <ModalProvider>
          <div className="">
          <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
          <DataTable
        title="Manage Librarians"
        columns={columns}
        data={searchInputField(librarians)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
            <Modal
              id="any-unique-identifier"
              isOpen={isModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to delete this Librarian?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={ () => {
                       deleteLibrarian({
                        _uid: userId,
                        _id
                      });
                      
                    }}
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </ModalProvider>
      )}
    </div>
  );
};


export default LibrarianListPage