import React from 'react'

import DataTable from 'react-data-table-component';


const FilterComponent = ({ filterText, onFilter }) => (
        <>
          <input id="search" className="my-2 p-2 border border-gray-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Name, Email" aria-label="Search Input" value={filterText} onChange={onFilter} />
          
        </>
      );

const PaymentHistory = ({ PaymentHistory, loading }) => {
        
        const { payment_history } = PaymentHistory

        const columns = [
                {
                  name: 'Name',
                  cell : payment_history => <div>{ payment_history.Name} </div>,
                  sortable: false,
                },
                {
                  name: 'Amount',
                  cell: payment_history => <div>₦ { payment_history.Amount }</div>
                },
                {
                  name : 'Status',
                  cell : payment_history => <div>{ payment_history.payment_status }</div>
                },
                {
                  name: 'Payment Reference',
                  cell : payment_history => <div>{ payment_history.payment_ref}</div>,
                  
                  
                },
               
              ];
            
              const customStyles = {
                rows: {
                  style: {
                    minHeight: '72px', // override the row height
                  }
                },
                headRow: {
                    style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderColor: 'red'
                  },
                },
                headCells: {
                  style: {
                    backgroundColor: 'green'
                  },
                },
                cells: {
                  style: {
                    
                  },
                },
              };
            
              const [filterText, setFilterText] = React.useState('');

              const searchInputField = (rows) => {
                if(!rows) return []
                return rows.filter(
                  (row) =>
                    row.Name.toLowerCase().includes(filterText.toLowerCase()) 
                  
                );
              };   
        return (
                <div className=" p-5 w-full">
            
            <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
          <DataTable
        title="Payment History"
        columns={columns}
        data={searchInputField(payment_history)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
      </div>
        )
}

export default PaymentHistory
