import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const ResourcesPrivateRouteHandler = ({ component, auth, ...rest }) => {

    let ComponentToRender = component;

    return (
        <Route
            {...rest}
            render={props =>
             auth.user.membership ? (
                    <ComponentToRender {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/resources",
                            
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps  = (state) => ({ auth: state.auth });
export const ResourcesPrivateRoute = withRouter(connect(mapStateToProps)(ResourcesPrivateRouteHandler));

