import {
  ADD_USER,
  DELETE_USER,
  MESSAGE,
  ADD_LIBRARIAN,
  DELETE_LIBRARIAN,
  UPDATE_PROFILE,
  GET_ERRORS
} from "../actions/types";


const initialState = {
    loading: false,
    error : ''
}

const adminReducer = ( state = initialState, action) => {
    switch (action.type) {
      case ADD_USER:
        return {
          ...state,
          loading: true,
          error: "",
        };
        case UPDATE_PROFILE :
          return{
            ...state,
            loading : true,
            error : ''
          }
      case MESSAGE:
        return {
          ...state,
          loading: false,
        };
      case DELETE_USER:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case ADD_LIBRARIAN:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case DELETE_LIBRARIAN:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case GET_ERRORS:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
}

export default adminReducer