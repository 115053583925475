import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux'
import http from '../../../utils/Axios'



import {
  ModalProvider,
  Modal,

  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";



const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="my-2 p-2 border border-gray-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Name, Email and Book" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);

const ReservedBooks = ( ) => {

  const  _id = localStorage.userId 

  const [ reservedBooks, setReservedBooks ] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [ singleBookData, setSingleBookData ] = useState({})
  const [ loading, setIsLoading ] = useState(false)


  // fetch issuedBooks
  const fetchReservedBooks = async () => {
          setIsLoading(true)
          try{
            const response = await http.get(`/api/book/reserved-books/${_id}`)
            console.log(response)
            setReservedBooks(response.data.data)
            setIsLoading(false)
          }catch(err){
            console.log(err)
          }
  }

  // cancelReservedBook
  const cancelReservedBook = async ( data ) => {
    try {
      const response = await http.post("/api/book/cancel-reserve", data)
      if(response){
        fetchReservedBooks()
      }
    } catch (err) {
      console.log(err)
    }
  }

  
  
  // useEffect for when the component mounts
  useEffect(() => {
    console.log('useEffect...')
    fetchReservedBooks()
  }, [])

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headRow: {
        style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderColor: 'red'
      },
    },
    headCells: {
      style: {
        backgroundColor: 'green'
      },
    },
    cells: {
      style: {
        
      },
    },
  };


  const columns = [
    {
      name: 'Name',
      cell : reservedBooks => <div>{ reservedBooks.borrowedBy.firstName} {reservedBooks.borrowedBy.lastName}</div>,
      sortable: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      maxWidth: '800px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: reservedBooks => <div>{ reservedBooks.borrowedBy.email }</div>
    },
    {
      name: 'Address',
      cell : reservedBooks => <div>{ reservedBooks.borrowedBy.address}</div>,
      sortable: true,
      
    },
    {
      name: 'Book',
      cell : reservedBooks => <div className="ml-4">
      <div className="text-sm font-medium text-gray-900">
      { reservedBooks.reserveBookId.title }
      </div>
      <div className="text-sm text-gray-500">
        { reservedBooks.reserveBookId.author }
      </div>
    </div>,
      sortable: true,
      style :{
        marginTop: '20'
      }
      
    },
    {
      name: 'Status',
      cell : () => <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-white">
      Unavailable
    </span>
      
    },
      {
      name: 'Action',
      button: true,
      cell:  reservedBooks => <div onClick={() => {
        setSingleBookData(reservedBooks)
        setIsModalOpen(true)}}  className="bg-red-700 cursor-pointer text-white p-2 rounded-md">
        cancel</div>
    }
  ];


const [filterText, setFilterText] = React.useState('');

const searchInputField = (rows) => {
  if(!rows) return []
  return rows.filter(
    (row) =>
      row.borrowedBy.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.borrowedBy.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
      row.borrowedBy.email.toLowerCase().includes(filterText.toLowerCase()) ||
      row.reserveBookId.title.toLowerCase().includes(filterText.toLowerCase())
  );
};

 
    return (
      <>
      <ModalProvider>
      <div className="px-5">
             <FilterComponent onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
              <DataTable
        title="Reserved Books"
        columns={columns}
        data={searchInputField(reservedBooks)}
        progressPending={loading}
      pagination
      paginationPerPage={10}
      
      
      width={'400px'}
      center={true}
      />
      </div>
      <Modal
              id="any-unique-identifier"
              isOpen={isModalOpen}
              transition={ModalTransition.BOTTOM_UP}
            >
              <div className="flex flex-col items-center justify-center mt-10">
                <h1 className="my-3 text-center">Are you sure</h1>
                <h1 className="my-3 text-center">
                  You want to cancel reservation for this Book?
                </h1>
                <div>
                  <button
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                  >
                    cancel
                  </button>

                  <button
                    onClick={ async () => {
                      await cancelReservedBook({
                        _id : localStorage.userId,
                        reservedBookId : singleBookData.reserveBookId._id,
                        reservedId: singleBookData._id
                      })
                       setIsModalOpen(false);
                    }}
                    className="bg-blue-500 text-white py-2 px-4 shadow-lg rounded-lg"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Modal>
     
      </ModalProvider>
      </>
    );
}


const mapStateToProps = state =>{
  return{
    auth: state.auth
  }
}

export default connect(mapStateToProps)(ReservedBooks)