import React from 'react';
import Slider from 'infinite-react-carousel'
import { Link } from 'react-router-dom'


import Testimonial from './Testimonial';
import Footer from '../../components/footer/Footer';
import { Parallax } from "react-parallax";

import image1 from '../../assets/img/peopleinlibrary.jpg'


import firstSlider from '../../assets/img/firstSlider.jpg'
import secondSlider from '../../assets/img/secondSlider.jpg'
import thirdSlider from '../../assets/img/thirdSlider.jpg'

const Home = () => {
    return (
      <div>
        <section className="text-gray-700 body-font">
          <div className=" flex flex-col items-center px-5 py-16 mx-auto lg:px-20 lg:py-24 md:flex-row">
            <div
              
              className=" w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 lg:mr-20 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0 lg:text-center"
            >
              <h2
                
                className="mb-1 text-md font-medium tracking-widest text-green-500 title-font"
              >
                FMCA Medical Library
              </h2>
              <h1 className="mb-8 text-2xl font-bold tracking-tighter  text-green-800 lg:text-left lg:text-5xl title-font">
                Welcome to FMC Abeokuta Medical Library
              </h1>
              <p className="mb-3 text-base leading-relaxed  text-gray-700 lg:text-left lg:text-1xl">
                Get medical resources both Online and Offline
              </p>
              <p className="mb-8 text-base leading-relaxed  text-gray-700 lg:text-left lg:text-1xl">
                Browse from the largest collection of medical books. Read from
                anywhere, at anytime.
              </p>
            </div>
            <div className="w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
              <img
                className="object-cover object-center rounded-lg "
                alt="hero"
                src="https://static.live.templately.com/2020/07/872fb83e-vactor.svg"
              />
            </div>
          </div>
        </section>
        {/* our process starts here */}
        <section className="mb-5">
          <div className="flex flex-col text-center items-center justify-center">
            <h1 className="text-green-700 text-2xl lg:text-3xl text-bold">
              Our Process
            </h1>
            <span className="h-1 w-20 block mt-4 bg-green-700"></span>
          </div>
          <div className=" container md:flex flex-col jusitfy-center items-center  ">
            <h1 className="text-gray-500 lg:text-1xl lg:text-center my-7 text-base leading-relaxed px-10">
              We offer a wide range of Medical Library services to our clients
              both online and offline.
            </h1>
          </div>
        </section>
        {/* our process ends here */}
        {/* pricing section starts here */}

        <section className="bg-green-700 text-gray-700 body-font">
          <div className="container px-8 py-8 mx-auto lg:px-48">
            <div className="md:flex items-center justify-center ">
              <div className=" lg:px-10 lg:w-1/2 md:w-full">
                <div className="h-full px-4 py-6 border rounded-xl bg-white">
                  <h3 className="tracking-widest text-green-700">Free Plan</h3>
                  <h2 className="flex items-center justify-start mt-2 mb-4 text-3xl font-bold leading-none text-left text-green-700 lg:text-6xl">
                    ₦0
                    <span className="ml-1 text-base text-green-700">/mo</span>
                  </h2>
                  <p className="mb-4 text-base leading-relaxed text-green-700">
                    Without being a member.
                  </p>
                  <p className="mb-4 text-base leading-relaxed text-green-700">
                    We have some packages reserved only for you
                  </p>
                  <p className="flex items-center mb-2 text-green-700">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 mr-2 text-white bg-green-700 rounded-full">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Limited Online Medical materials
                  </p>
               
                  <Link to="/user/register">
                    <button className="items-end w-full px-8 py-2 mt-12 font-semibold text-white transition duration-500 ease-in-out transform bg-green-700 border rounded-lg shadow-xl hover:text-white focus:shadow-outline focus:outline-none hover:bg-black hoveer:border-black">
                      Sign Up
                    </button>
                  </Link>
                </div>
              </div>
              <div className=" my-5 md:my-0 mx-auto lg:px-10 lg:w-1/2 md:w-full ">
                <div className="h-full px-4 py-6 border rounded-xl ">
                  <h3 className="tracking-widest text-white">PAID PLAN</h3>
                  <h2 className="flex items-center justify-start mt-2 mb-4 text-3xl font-bold leading-none text-left text-white lg:text-6xl">
                    ₦3800
                    <span className="ml-1 text-base text-white">/mo</span>
                  </h2>
                  <p className="mb-4 text-base leading-relaxed text-white">
                    Our paid plan gives you access to FMCA online and offline medical library resources 
                  </p>
                  <p className="flex items-center mb-2 text-white">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 mr-2 text-green-700 bg-white rounded-full">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Unlimited online resources
                  </p>
                  <p className="flex items-center mb-2 text-white">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 mr-2 text-green-700 bg-white rounded-full">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Access to our HINARY parks
                  </p>
                  <p className="flex items-center mb-6 text-white">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 mr-2 text-green-700 bg-white rounded-full">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    And so much more
                  </p>
                  <Link to="/user/register">
                  <button
                    
                    className="w-full px-8 py-2 font-semibold textgreen-700 transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-white text-green-700 hover:bg-gray-200 hover:text-green-900 focus:shadow-outline focus:outline-none"
                  >
                    Sign Up
                    </button>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* carousel section */}
        <section className="mb-2 " >
          <div>
        <Slider  dots autoplay={true} arrows={false} adaptiveHeight={true} className="h-2/5">
            <div className=" h-2/5 bg-red-100 ">
               <img className=" h-full w-full" src={ firstSlider } alt="" /> 
              
            </div>
            <div className=" h-2/5 bg-green-100">
               <img className=" h-full w-full" src={ secondSlider } alt="" /> 
              
            </div>
            <div className=" h-2/5 bg-red-700">
               <img className=" h-full w-full" src={ thirdSlider } alt="" /> 
              
            </div>
        </Slider>
        </div>
        </section>


        {/* Testimonial Section starts here*/}
        <section className="pt-36 body-font">
          <div className="flex flex-col text-center items-center justify-center">
            <h1
              
              className="text-green-700 text-md lg:text-2xl text-bold"
            >
              Testimonials
            </h1>
            <h1 className="text-green-700 text-lg lg:text-4xl text-bold">
              What people are saying about us
            </h1>
            <span className="h-1 w-20 block mt-4 bg-green-700"></span>
          </div>
          <Testimonial />
        </section>
        

        {/* parallax section */}
        <section>
        <Parallax bgImage={image1} strength={-100}>
      <div style={{ height: 500 }}>
        
      </div>
    </Parallax>

        </section>
        {/* Testimonial section ends here */}
        <Footer />
      </div>
    );
}

export default Home