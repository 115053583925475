import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getCurrentProfile } from '../Redux/actions/profileActions'
import imageSuccessfull from '../assets/img/payment_success.jpg'

const PaymentSuccessful = ({ getCurrentProfile }) => {

    useEffect(() => {
        getCurrentProfile()
    }, [])
    return (
        <div className="px-5 py-3">
            <div className="flex flex-col items-center justify-center">
                <div>
                    <img src={imageSuccessfull} alt="success payment" className="h-96" />
                </div>

                <div className="text-center">
                    <h1>Your Payment is successful</h1>
                    <h1>Thank you for your payment, an automated Library Card has been generated on your profile page</h1>
                    <h1>You can now enjoy unlimited access to our Library resources</h1>
                </div>

            </div>
        </div>
    )
}

export default connect(null, { getCurrentProfile })(PaymentSuccessful)
