import React from 'react'
import { Link } from 'react-router-dom'

const Library = () => {
    return (
        <div className="p-2 md:p-5">
            <div className="md:grid grid-cols-3 gap-4  justify-center items-center text-center">
                <div>
                <Link to="/resources/digital-books">
            <div className="p-5 bg-gradient-to-r from-green-900 via-green-700 to-green-500  text-white mt-4 md:ml-3 h-44 w-30 rounded-lg flex flex-col items-center justify-center shadow-2xl hover:bg-green-900 cursor-pointer ">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                />
              </svg>

              <h1>Digital</h1>
            </div>
          </Link>
                </div>
                <div>
                <Link to="/resources/physical-books">
            <div className="p-5 bg-gradient-to-r from-green-900 via-green-700 to-green-500  text-white mt-4 md:ml-3 h-44 w-30 rounded-lg flex flex-col items-center justify-center shadow-2xl hover:bg-green-900 cursor-pointer ">
              
              <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" /></svg>

              <h1>Offline Resources</h1>
            </div>
          </Link>
                </div>
            </div>
        </div>
    )
}

export default Library
