import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import {
  ModalProvider,
  Modal,

  ModalTransition,
} from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";

import { reserveBook, borrowBook } from '../../Redux/actions/bookActions'
import http from '../../utils/Axios';

import DataTable from 'react-data-table-component';





const token = localStorage.jwtToken

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <input id="search" className="p-2 border border-green-700 rounded-md shadow-md w-full" type="text" placeholder="Filter By Title and Author" aria-label="Search Input" value={filterText} onChange={onFilter} />
    
  </>
);


const PhysicalBooks = ({  reserveBook ,borrowBook, book}) => {
        
        
        const [ physicalBooks, setPhysicalBooks ] = useState([])
        const [ isLoading, setIsLoading ] = useState(false)
        const [ isModalOpen, setIsModalOpen ] = useState(false)
        const  _id = localStorage.userId


          // handle borrowed books
        const handleBorrowedBooks = (bookId) => {
          borrowBook({bookId :bookId, _id:_id})
          setIsModalOpen(true)
        }

        // handleReservedBooks
        const handleReservedBooks = (bookId) => {
          
           reserveBook({bookId : bookId, _id:_id})
           setIsModalOpen(true)
        }
        

    useEffect(() => {
      setIsLoading(true)
      
      http
        .get(`/api/book/view/physical/${_id}`,{
          
          headers: {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
           'Authorization': `Bearer ${token}`,
          }
        })
        .then((res) => {
                setPhysicalBooks(res.data.data)
                setIsLoading(false)
        })
        .catch(err =>{
           console.log(err.message)
           console.log(err.code)

          });
    },[_id]);
    const columns = [
      
      {
        name: 'Author',
        selector: 'author',
        sortable: true,
        
      },

      {
        name: 'Title',
        selector: 'title',
        sortable: true,
        style:{
          paddingTop: '10px',
          paddingBottom: '10px',
          width: '500px'
        },
        cell: physicalBooks => <div > { physicalBooks.title } </div>,
      },
      {
        name: 'Year-Total pages',
        sortable: true,
        cell : physicalBooks => <div>{physicalBooks.year}-{physicalBooks.totalPages+ "Pages"}</div>
        
      },
      {
        name: 'Quantity',
        selector: 'quantity',
        
      },
      {
        name : 'Action',
        button : true,
        cell : physicalBooks => <div>{
          physicalBooks.borrowedCount === physicalBooks.quantity ? (
                  <button className="py-2 px-3 bg-green-700 text-white" onClick={() => handleReservedBooks(physicalBooks._id)}>Reserve</button>
          ) : (
                  <button className="py-2 px-3 bg-green-700 text-white" onClick={() => {handleBorrowedBooks(physicalBooks._id)
                  console.log(physicalBooks._id)
                  }}>Borrow</button>
          )
  }</div>
}
    ];
    
    const customStyles = {
      rows: {
        style: {
          minHeight: '72px', // override the row height
        }
      },
      headRow: {
          style: {
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderColor: 'red'
        },
      },
      headCells: {
        style: {
          backgroundColor: 'green'
        },
      },
      cells: {
        style: {
          
        },
      },
    };
    
    
   
    const [filterText, setFilterText] = React.useState('');

const searchInputField = (rows) => {
  if(!rows) return []
  return rows.filter(
    (row) =>
      row.title.toLowerCase().includes(filterText.toLowerCase()) ||
      row.author.toLowerCase().includes(filterText.toLowerCase())
  );
};
   
    return (
      <div className="p-4">
                  <ModalProvider>
      
                                       <div>
                    <div className="flex flex-col">
                    <FilterComponent className="my-3" onFilter={e => setFilterText(e.target.value)}  filterText={filterText} />
         
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
         
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <DataTable
        title="Library  Books"
        columns={columns}
        data={searchInputField(physicalBooks)}
        progressPending={isLoading}
      pagination
      paginationPerPage={10}
      
      style={customStyles}
      width={'400px'}
      center={true}
      />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                            id="any-unique-identifier"
                            isOpen={isModalOpen}
                            transition={ModalTransition.BOTTOM_UP}
                          >
                            <div className="flex flex-col items-center justify-center mt-10">
                              <h1 className="my-3 text-center">{
                                !book.error ? book.message : book.error
                              }</h1>
                              <div>
                                <button
                                  onClick={() => {
                                    setIsModalOpen(false);
                                  }}
                                  className="bg-red-500 text-white py-2 px-4 shadow-lg rounded-lg mr-3"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </Modal>
                  </div>
            </ModalProvider>
      </div>
    );
}


const mapStateToProps = state => {
  return{

    book : state.book,
  }
}



export default connect(mapStateToProps, { reserveBook, borrowBook })(PhysicalBooks)




