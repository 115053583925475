import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../utils/Axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,

} from "@fortawesome/free-solid-svg-icons";
import PaymentHistoryComp from './PaymentHistory';

const AdminDashboard = ({ match }) => {
    const token = localStorage.jwtToken;
  
  const [users, setUsers] = useState([]);
  const [ PaymentHistory, setPaymentHistory ] = useState({})
  const [ loading, setLoading ] =useState(false)
  

  const failedNotify = (message) => toast.error( message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });


    // fetch users to get the list of available users
        const fetchUsers = async () => {
          setLoading(true)
          try {
            const response = await  http.get(`/api/auth/members/${localStorage.userId}`, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                Authorization: "Bearer " + token,
              },
            })
            setUsers(response.data.data)
            setLoading(false)
          } catch (error) {
            error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
     
            setLoading(false)
          }
        }

        // get payment history
        const getPaymentHistory = async () => {
          setLoading(true)
          try {
            const response = await  http.get(`/api/payment/history/${localStorage.userId}`, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                Authorization: "Bearer " + token,
              },
            })
            setPaymentHistory(response.data.data)
            setLoading(false)
          } catch (error) {
            error.message === "Network Error" ? failedNotify(error.message) : failedNotify(error.message)
     
            setLoading(false)
          }
        }
    
     useEffect(() => {
       fetchUsers()
     }, [])

     useEffect(() => {
      getPaymentHistory()
     }, [])

     
     const registeredUsers = users.filter(user => user.membership === true )

     const nonRegisteredUsers = users.filter(user => user.membership === false )
     
    
     
    return (
        <div className="p-5">
          <ToastContainer />
           <div className="md:grid grid-cols-3 gap-3 ">
                <div to="/admin/dashboard/all-users" className="bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 h-48 mt-2 shadow-md rounded-lg text-white p-3">
                    <h1 className="text-xl">Registered Users</h1>
                    <div className="flex items-center justify-between my-3">
                        <div >
                        <svg className="w-14 h-14" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
                    </div>
                    <h1 className="text-4xl">{ registeredUsers.length }</h1>
                    </div>
                </div>
                <div className="bg-gradient-to-r from-yellow-600 to-red-600 h-48 shadow-md rounded-lg mt-2 text-white p-3">
                    <h1 className="text-xl">Non-Registered Users</h1>
                    <div className="flex items-center justify-between my-3">
                    <FontAwesomeIcon icon={faUsers} size="5x" />
                    <h1 className="text-4xl">{ nonRegisteredUsers.length }</h1>
                    </div>
                </div>
                <div className="bg-gradient-to-r from-green-500 to-green-700 h-48 shadow-md rounded-lg mt-2 text-white p-3">
                    <h1 className="text-xl">Wallet</h1>
                    <div className="flex items-center justify-between my-3">
                    <div>
                    <svg className="w-14 h-14" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" /></svg>
                    </div>
                    <h1 className="text-4xl">₦ { !PaymentHistory.wallet_balance ? 0 : PaymentHistory.wallet_balance }</h1>
                    </div>
                </div>
           </div>
           <Switch>

           <Route path={`${match.url}/payment-history`} render={(props) => <PaymentHistoryComp { ...props } PaymentHistory={ PaymentHistory } loading={ loading} />}  />
           </Switch>
          
        </div>
    )
}

export default AdminDashboard
